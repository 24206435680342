import {React,useState,useEffect} from 'react';
import { Flex, Divider,Box,Text, Wrap, Image } from '@chakra-ui/react';
import { motion,AnimatePresence } from 'framer-motion';
import {  Element } from 'react-scroll';
import { FaRegLightbulb,FaTwitter, FaRoadCircleCheck,FaTrafficLight } from 'react-icons/fa6';
import { MdFacebook } from "react-icons/md";
import { AiFillInstagram } from "react-icons/ai";
import { dataRuasJalan } from '../../Components/db/dummyruasjalan';
import DataTable from 'react-data-table-component';
import './home.css'
import axios from 'axios';
import { TabTitle } from '../../Components/Admin/Utils/config';
import { APIRuasJalan } from '../../Components/Admin/Utils/API/api';
import { Link,NavLink } from 'react-router-dom';
const Home = () => {
  const imageUrl = `${process.env.PUBLIC_URL + '/street.png'}`;
  const Columns = [
    {
      name: "No",
      selector: "id_ruas_jalan",
      sortable: true,
      width: "100px",
    },
    {
      name: "Nama Ruas Jalan",
      selector: "nama_ruas_jalan",
      sortable: true,
      width: "280px",
    },
    {
      name: "Panjang (km)",
      selector: "panjang",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    {
		name: "Fungsi",
		selector: "fungsi",
		sortable: true,
	},
	{
		name: "Remark",
		selector: "remark",
		sortable: true,
	},
	{
		name: "Kabupaten",
		selector: "kabupaten",
		sortable: true,
	}
  ];
  TabTitle("Home")
  const customStyles = {
    headCells: {
        style: {
            backgroundColor: "#0e1d34",
            color: "#ffffff",
        },
    },
  };
  const [ruasJalan, setRuasJalan] = useState([])
  const fetchData = async () => {
    try {
      const response = await axios.get(APIRuasJalan.show).then((res)=>{
        setRuasJalan(res.data);
      })
    } catch (error) {
        console.log(error);     
    }
  }
  const [shouldAnimate, setShouldAnimate] = useState(false);
  useEffect(() => {
    fetchData()
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const elementOffset = document.getElementById('animated-section').offsetTop;
      const threshold = elementOffset - window.innerHeight / 2; // Adjust this threshold as needed
      setShouldAnimate(scrollPosition > threshold);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
    <Box
        width={'100%'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        minHeight="100vh" 
        overflowY="hidden"
        overflowX={'none !important'} 
        >
        <Box 
          bgGradient='linear(to-b, #E9FCFE, #FAE4ED)' 
          width={'100%'}
          display="flex"
          flexDirection="column"
          alignItems="center"
          minHeight="100vh"
        >
          <Box
            minHeight="100vh"
            width={'100%'}
            bgPos={'bottom'} 
            bgSize={'unset'} 
            bgRepeat={'no-repeat'}
            bgImage={`url(${imageUrl})`}
          >
          <Wrap ml={5} display={{base: 'flex', sm:'none', md:'none'}} mt={'30%'} alignContent={'flex-start'} textAlign={'left'} alignItems={'flex-start'} alignSelf={'flex-start'} width={'80%'}>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.5 }}
            >
            <Text color={'#0A0D13'} fontFamily={'inter'} fontSize={'21.69px'} mt={'30%'}>Selamat datang di, 
            </Text>
              <Text color={'#0A0D13'} fontFamily={'inter'} fontSize={'30px'} fontWeight={'bold'}>
              Sistem Informasi Prasarana Jalan (SiPANJA)   
              </Text>
              <Text color={'#0A0D13'} fontFamily={'inter'} fontSize={'16px'} >
              Dinas Perhubungan Provinsi Lampung   
              </Text>
            </motion.div>
          </Wrap>
          <Wrap ml={5} display={{base: 'none', sm:'flex', md:'none'}} mt={'30%'} alignContent={'flex-start'} textAlign={'left'} alignItems={'flex-start'} alignSelf={'flex-start'} width={'80%'}>
          <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.5 }}
            >
            <Text color={'#0A0D13'} fontFamily={'inter'} fontSize={'22.764px'}>Selamat datang di, 
            </Text>
              <Text color={'#0A0D13'} fontFamily={'inter'} fontSize={'48px'} fontWeight={'bold'}>
              Sistem Informasi Prasarana Jalan (SiPANJA)   
              </Text>
              <Text color={'#0A0D13'} fontFamily={'inter'} fontSize={'16px'} >
              Dinas Perhubungan Provinsi Lampung   
              </Text>
            </motion.div>
          </Wrap>
          <Wrap ml={'5%'} display={{base: 'none', sm:'none', md:'flex', lg:'none'}} mt={'30%'} alignContent={'flex-start'} textAlign={'left'} alignItems={'flex-start'} alignSelf={'flex-start'} flexDir={'column'}  width={'70%'}>
          <motion.div
               initial={{ opacity: 0, y: 20 }}
               animate={{ opacity: 1, y: 0 }}
               transition={{ duration: 0.8, delay: 0.5 }}
            >
              <Text color={'#0A0D13'} fontFamily={'inter'} fontSize={'23.472px'}>Selamat datang di,</Text><br/>
              <Text color={'#0A0D13'} fontFamily={'inter'} fontSize={'48px'} fontWeight={'bold'}> Sistem Informasi Prasarana Jalan (SiPANJA)</Text><br/>
              <Text color={'#0A0D13'} fontFamily={'inter'} fontSize={'16px'} >Dinas Perhubungan Provinsi Lampung</Text><br/>
            </motion.div>
          </Wrap>
          <Wrap ml={'5%'} display={{base: 'none', sm:'none', md:'none', lg:'flex', xl:'none'}} mt={'20%'} alignContent={'flex-start'} textAlign={'left'} alignItems={'flex-start'} alignSelf={'flex-start'} flexDir={'column'}  width={'70%'}>
          <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.5 }}
            >
              <Text color={'#0A0D13'} fontFamily={'inter'} fontSize={'24px'}>Selamat datang di,</Text><br/>
              <Text color={'#0A0D13'} fontFamily={'inter'} fontSize={'48px'} fontWeight={'bold'}> Sistem Informasi Prasarana Jalan (SiPANJA)</Text><br/>
              <Text color={'#0A0D13'} fontFamily={'inter'} fontSize={'16px'} >Dinas Perhubungan Provinsi Lampung</Text><br/>
            </motion.div>
          </Wrap>
          <Wrap ml={'5%'} display={{base: 'none', sm:'none', md:'none', lg:'none', xl:'flex'}} mt={'13%'} alignContent={'flex-start'} textAlign={'left'} alignItems={'flex-start'} alignSelf={'flex-start'} flexDir={'column'}  width={'50%'}>
          <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.5 }}
            >
              <Text color={'#0A0D13'} fontFamily={'inter'} fontSize={'24px'}>Selamat datang di,</Text>
              <Text color={'#0A0D13'} fontFamily={'inter'} fontSize={'48px'} fontWeight={'bold'}> Sistem Informasi Prasarana Jalan (SiPANJA)</Text>
              <Text color={'#0A0D13'} fontFamily={'inter'} fontSize={'16px'} >Dinas Perhubungan Provinsi Lampung</Text>
            </motion.div>
          </Wrap>
          </Box>
          </Box>
          <Box 
          id="animated-section"
          width={{'base':'100%','md':'90%'}}
          mx={{'base':'0','md':'auto'}}
          flexDirection="column"
          minHeight="100vh"
          >
          <Element name="animated-section">
            <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: shouldAnimate ? 1 : 0, y: shouldAnimate ? 0 : 30 }}
                transition={{ duration: 0.8, delay: 0.8 }}
            >
             <Flex 
              justifyContent={{'base':'flex-start','sm':'center', 'md': "center"}}
              alignContent={'center'} 
              pt={'120px'} 
              alignSelf={'flex-start'} 
              alignItems={{'base':"flex-start",}} 
              mt={{'base':'50px'}} 
              flexDir={{'base':'column','sm':'column', 'md': 'row'}} width={{'base':'80%','sm':'90%','md':'100%'}}>
              <Flex 
                mb={20}
                flexDir={'row'}
                className="hover-container" 
                width={'100vw'}>
              <Flex  alignContent={'center'} mr={5}>
                <FaTrafficLight className="hover-icon" color='#001f8d' fontSize={'48px'} />
              </Flex>
              <Flex justify={'start'} flexDir={'column'} width={'100%'}>
                <Link to='/kebutuhan_ruas'>
                <Text 
                  textAlign={'left'} 
                  className="hover-icon"
                  color={'#0e1d34'} 
                  fontWeight={'bold'} 
                  fontSize={'20px'}
                  > 
                  Kebutuhan Ruas
                </Text>
                </Link>
                <Text>
                  Menyajikan data perihal kebutuhan sesuai ruas jalan Provinsi.
                </Text>
              </Flex>
              </Flex>
              <Flex
                mb={20}
                flexDir={'row'}
                className="hover-container" 
                width={'100vw'}
            >
              <Flex alignContent={'center'} mr={5}>
                <FaRoadCircleCheck
                  className="hover-icon" color='#001f8d' fontSize={'48px'}
                />
              </Flex>
              <Flex  justify={'start'} flexDir={'column'} width={'100%'}>
                <NavLink to="/ruas_terpasang">
                <Text
                  textAlign={'left'} 
                  className="hover-icon"
                  color={'#0e1d34'} 
                  fontWeight={'bold'} 
                  fontSize={'20px'}
                  >
                  Ruas Terpasang
                </Text>
                  </NavLink>
                <Text>
                  Menyajikan data perihal pemasangan sesuai ruas jalan Provinsi.
                </Text>
                </Flex>
              </Flex>
              <Flex  
                mb={20}
                flexDir={'row'}
                className="hover-container" 
                width={'100vw'}
              >
                <Flex alignContent={'center'} mr={5}>
                  <FaRegLightbulb className="hover-icon" color='#001f8d' fontSize={'48px'} />
                </Flex>
                <Flex  justify={'start'} flexDir={'column'} width={'100%'}>
                <NavLink to='/saran_aduan'>
                <Text 
                textAlign={'left'} 
                className="hover-icon"
                color={'#0e1d34'} 
                fontWeight={'bold'} 
                fontSize={'20px'}
                >
                  Saran dan Aduan
                </Text>
                </NavLink>
                <Text>
                  Menyajikan data perihal aduan dan saran di SiPANJA.
                </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex width={'100%'} justify={'center'}>
            <Box
              id="animated-section"
              borderRadius={'10px'}
              display="flex"
              width={{"base":"80%",'md':'50%'}}
              height={'50vh'}
              as="iframe"
              title="dishub"
              src="https://www.youtube.com/embed/rB7DoW28G8I" 
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              />
            </Flex> 
          </motion.div>
          </Element>
          </Box> 
            <Box 
              bg={'#e8feff7d'}
              width={'100%'}
              my={'7.5vh'}
              py={'7.5vh'}
              display="flex"
              flexDirection="column"
              alignItems="center"
              minHeight="70vh"
              id="animated-section"
            >
          <Element  name="animated-section">
          <AnimatePresence>
          {shouldAnimate && (
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.8 }}
              exit={{ opacity: 0, y: 30 }}
            >
            <Flex mx={'10px'} width={{'base':'95%','lg':'90%'}} justify={{'md':'center','lg':'center'}} flexDir={{'base':'column', 'md':'row'}}>
              <Flex alignContent={'center'} width={{md:'90%'}}>
                <Image height={'fit-content'} width={'100%'} src={process.env.PUBLIC_URL + '/Road_sign-pana.png'} />
              </Flex>
              <Flex flexDir={'column'}>
              <Flex mb={'8px'} width={{'base':'90%'}}>
                <Text
                  lineHeight={'1.2'}
                  fontSize={'33.6px'}
                  fontWeight={'medium'}
                  fontFamily={'inter'}
                  color={'rgba(33,37,41,1)'}
                  textAlign={'left'}>
                    SiPANJA Memiliki Informasi Pengelolaan Perlengkapan Jalan
                </Text>
              </Flex>
              <Flex mt={2}>
                <Divider border={'0.6px solid'} opacity={'0.25'}  />
              </Flex>
              <Flex mt={5}>
                <Image h={'20px'} w={'20px'} mr={'16px'} src={process.env.PUBLIC_URL + '/tick.png'} /> 
                <Text>
                  Rambu Lalu lintas, Marka Jalan.
                </Text>
              </Flex>
              <Flex mt={5}>
                <Image h={'20px'} w={'20px'} mr={'16px'} src={process.env.PUBLIC_URL + '/tick.png'} /> 
                <Text>
                   Alat Pemberi Isyarat Lalu Lintas (APILL).
                </Text>
              </Flex>
              <Flex mt={5}>
                <Image h={'20px'} w={'20px'} mr={'16px'} src={process.env.PUBLIC_URL + '/tick.png'} /> 
                <Text>
                   Alat Penerangan Jalan.
                </Text>
              </Flex>
              <Flex mt={5}>
                <Image h={'20px'} w={'20px'} mr={'16px'} src={process.env.PUBLIC_URL + '/tick.png'} /> 
                <Text>
                  Alat Pengendali dan Pengaman Pengguna Jalan.
                </Text>
              </Flex>
              <Flex mt={5}>
                <Image h={'20px'} w={'20px'} mr={'16px'} src={process.env.PUBLIC_URL + '/tick.png'} /> 
                <Text>
                  Alat Pengawasan dan Pengaman Jalan.
                </Text>
              </Flex>
              <Flex mt={5}>
                <Image h={'20px'} w={'20px'} mr={'16px'} src={process.env.PUBLIC_URL + '/tick.png'} /> 
                <Text>
                Fasilitas pendukung kegiatan lalu lintas dan angkutan jalan yang berada di jalan dan atau diluar badan jalan.
                </Text>
              </Flex>
              </Flex>
            </Flex>
          </motion.div>
          )}
        </AnimatePresence>
      </Element>
      </Box>
      <Box
        width={'100%'}
        flexDirection="column"
        alignItems="center"
        justifyContent={'center'}
        minHeight="60vh"
        alignContent={'center'}
        >
        <Flex 
          width={'100%'}
          flexDirection="column"
          alignItems="center"
          justifyContent={'center'}
          minHeight="60vh"
          alignContent={'center'}

        >
        <Box
				maxWidth={"calc(100vw - 2rem)"}
				width={"90%"}
				margin={{
					base: "0rem",
					md: "0rem",
				}}
        borderRadius={'10px'}
        alignItems="center"
        justifyContent={'center'}
        minHeight="60vh"
        alignContent={'center'}
			>
          <Text fontWeight={'semibold'} fontSize={'28px'} color={'#0A0D13'}>
            Ruas Jalan Provinsi Lampung
          </Text>
          <Divider my={'0.5'} width={'99%'} border={'0.6px solid'} opacity={'0.25'}  />
        <Box 
          pt={'2vh'}
          mb={'5vh'}
          borderRadius={'10px'}
          display="flex"
          boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
          flexDirection="column"
          justifyContent={'center'}
          
          >
            <Box
            maxWidth={"calc(100vw - 2rem)"}
            width={"100%"}
            margin={{
              base: "0rem",
              md: "0rem",
            }}
            borderRadius={'10px'}
            >
            <DataTable 
              columns={Columns}
              data=
              {ruasJalan.map(ruas=>({
                ...ruas,
                id_ruas_jalan : ruas.id_ruas_jalan,
                nama_ruas_jalan : ruas.nama_ruas_jalan,
                panjang : ruas.panjang,
                status: ruas.status,
                fungsi: ruas.fungsi,
                remark: ruas.remark,
                kabupaten:ruas.kabupaten.nama_kabupaten
              }))}
              pagination
              customStyles={customStyles}
              striped
              paginationPerPage={10}
              paginationComponentOptions={{
                rowsPerPageText: 'Baris per halaman:',
                rangeSeparatorText: 'dari',
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: 'Semua'
              }}
              highlightOnHover
              pointerOnHove
              responsive
              noDataComponent={<Text>Tidak ada data</Text>}
              />
            </Box>  
        </Box>
      </Box>
        </Flex>
      </Box>
      
    </Box>
    </>
  );
}
export default Home;