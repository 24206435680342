import React, { useEffect, useState } from "react"
import axios from "axios"
import { useDispatch } from "react-redux"
import { Image } from "@chakra-ui/react"

import Action from "../../../../Components/Admin/Table/Action/action"
import { routePageName } from "../../../../Components/Admin/Utils/Redux/action"
import { TabTitle } from "../../../../Components/Admin/Utils/config"
import ContainerBox from "../../../../Components/Admin/Table/Container/container"
import { ExpandRuasJalan } from "./expandruasjalan"

import FormRuasJalan from "./formruasjalan"
import {
	APIKabupaten,
	APIRuasJalan,
} from "../../../../Components/Admin/Utils/API/api"
import useAuth from "../../../../middleware/useAuth"
import getUserLogin from "../../../../middleware/getUserLogin"

const HandlerFilter = (data, e) => {
	const keyword = e.target.value
	if (keyword !== "") {
		const results = data.filter((data) => {
			return (
				data.nama_ruas_jalan
					.toLowerCase()
					.includes(keyword.toLowerCase()) ||
				// data.panjang.toLowerCase().includes(keyword.toLowerCase()) ||
				data.status.toLowerCase().includes(keyword.toLowerCase()) ||
				data.fungsi.toLowerCase().includes(keyword.toLowerCase()) ||
				data.remark.toLowerCase().includes(keyword.toLowerCase()) ||
				data.kabupaten.nama_kabupaten
					.toLowerCase()
					.includes(keyword.toLowerCase()) ||
				data.sumber.toLowerCase().includes(keyword.toLowerCase()) ||
				data.start_latitude
					.toLowerCase()
					.includes(keyword.toLowerCase()) ||
				data.start_longitude
					.toLowerCase()
					.includes(keyword.toLowerCase()) ||
				data.end_latitude
					.toLowerCase()
					.includes(keyword.toLowerCase()) ||
				data.end_longitude.toLowerCase().includes(keyword.toLowerCase())
			)
		})
		return results
	} else {
		return data
	}
}

const API = APIRuasJalan
const API2 = APIKabupaten
const name = "Ruas Jalan"
const Form = FormRuasJalan

const PageRuasJalan = () => {
	useAuth()

	const [user, setUser] = useState()
	const linkCreate = API.create
	const filter = HandlerFilter

	TabTitle(name)
	const [data, setData] = useState()
	const [dataCategory, setDataCategory] = useState()

	const dispatch = useDispatch()
	const patchRoute = (data) => {
		dispatch(routePageName(data))
	}

	const Columns = [
		{
			name: "No",
			selector: "id_ruas_jalan",
			sortable: true,
			width: "100px",
		},
		{
			name: "Nama Ruas Jalan",
			selector: "nama_ruas_jalan",
			sortable: true,
			width: "280px",
		},
		{
			name: "Panjang (km)",
			selector: "panjang",
			sortable: true,
		},
		{
			name: "Status",
			selector: "status",
			sortable: true,
		},
		// {
		// 	name: "Fungsi",
		// 	selector: "fungsi",
		// 	sortable: true,
		// },
		// {
		// 	name: "Remark",
		// 	selector: "remark",
		// 	sortable: true,
		// },
		// {
		// 	name: "Kabupaten",
		// 	selector: "kabupaten",
		// 	sortable: true,
		// },
		// {
		// 	name: "Sumber",
		// 	selector: "sumber",
		// 	sortable: true,
		// },
		// {
		// 	name: "Start Latitude",
		// 	selector: "start_latitude",
		// 	sortable: true,
		// },
		// {
		// 	name: "Start Longitude",
		// 	selector: "start_longitude",
		// 	sortable: true,
		// },
		// {
		// 	name: "End Latitude",
		// 	selector: "end_latitude",
		// 	sortable: true,
		// },
		// {
		// 	name: "End Longitude",
		// 	selector: "end_longitude",
		// 	sortable: true,
		// },
		user === "super admin"
			? {
					name: "Aksi",
					center: true,
					width: "240px",
					cell: (row) => (
						<Action
							id={row.id_ruas_jalan}
							nameSelected={row.nama_ruas_jalan}
							category={dataCategory}
							APIEdit={API.edit}
							APIDelete={API.delete}
							row={row}
							Edit={Form}
							name={name}
							fetchData={fetchData}
						/>
					),
			  }
			: {
					width: "0px",
			  },
	]

	const fetchData = async () => {
		try {
			axios.get(API.show).then((response) => {
				setData(response.data)
			})
		} catch (error) {
			console.log(error)
		}

		try {
			axios.get(API2.show).then((response) => {
				setDataCategory(response.data)
			})
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		fetchData()
		patchRoute(name)
		getUserLogin().then((res) => {
			setUser(res.role)
		})
	}, [])

	return (
		<ContainerBox
			user={user}
			Create={Form}
			name={name}
			records={data}
			columns={Columns}
			filter={filter}
			linkCreate={linkCreate}
			expand={true}
			ComponentExpanded={ExpandRuasJalan}
			category={dataCategory}
			fetchData={fetchData}
		/>
	)
}

export default PageRuasJalan
