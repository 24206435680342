import React from "react"
import { Flex, Box } from "@chakra-ui/react"
import { Outlet } from "react-router-dom"

import Sidebar from "./Sidebar/sidebar"
import Navbar from "./Navbar/navbar"
import useAuth from "../../../middleware/useAuth"

const Board = () => {
	return (
		<Flex color="white" width="100%">
			<Box>
				<Sidebar />
			</Box>
			<Box flex="1">
				<Navbar />
				<Flex
					w="100%"
					h={"calc(100vh - 100px)"}
					overflowY={"scroll"}
					flexDir="column"
				>
					<Outlet />
				</Flex>
			</Box>
		</Flex>
	)
}
export default Board
