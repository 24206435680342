import { Box, Text, SimpleGrid } from "@chakra-ui/layout"
import React, { useState, useEffect } from "react"

import { BsInfoCircle, BsCheckCircle } from "react-icons/bs"
import { IoWarningOutline } from "react-icons/io5"
import { FaRoad, FaCarCrash } from "react-icons/fa"
import { MdCategory, MdLabel, MdLocationOn } from "react-icons/md"
import { TabTitle } from "../../../Components/Admin/Utils/config"
import AdminCard from "../../../Components/Admin/Table/Card/admincard"
import { APICounter } from "../../../Components/Admin/Utils/API/api"
import axios from "axios"
import useAuth from "../../../middleware/useAuth"
import getUserLogin from "../../../middleware/getUserLogin"

const Dashboard = () => {
	useAuth()
	const [user, setUser] = useState([])
	const [data, setData] = useState([])
	TabTitle("Dashboard")
	const fetchData = async () => {
		try {
			axios.get(APICounter.count).then((response) => {
				setData(response.data)
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchData()
		getUserLogin().then((res) => {
			setUser(res.role)
		})
	}, [])
	return (
		<Box
			alignContent={"center"}
			bg="white"
			borderRadius={{
				base: "none",
				md: "lg",
			}}
			boxShadow={{
				base: "none",
				md: "lg",
			}}
			border={{
				base: "none",
				md: "1px solid #e2e8f0",
			}}
			my={{
				base: 0,
				md: 5,
			}}
			p={{
				base: 0,
				md: 5,
			}}
			alignSelf={"center"}
			flexDir={{ base: "column", md: "row", lg: "row" }}
			width={{ base: "100%", md: "95%", lg: "95%", xl: "100%" }}
			maxWidth={{ base: "100%", md: "100%", lg: "100%", xl: "95%" }}
		>
			<Box
				width={"100%"}
				alignContent={"center"}
				justifyContent={"center"}
				mb={20}
			>
				<Text
					ml={5}
					mb={5}
					fontWeight={"semibold"}
					fontSize={"var(--header-3)"}
					color={"var(--color-primer)"}
				>
					Prasarana Jalanan
				</Text>

				<Box
					display={"flex"}
					justifyContent={"center"}
					alignContent={"center"}
					alignItems={"center"}
					width={"100%"}
				>
					<SimpleGrid
						spacing={20}
						templateColumns={{
							base: "repeat(1, 1fr)",
							md: "repeat(3, 1fr)",
							lg: "repeat(3, 1fr)",
							xl: "repeat(3, 1fr)",
						}}
					>
						<AdminCard
							Icon={BsInfoCircle}
							counter={data.kebutuhan}
							name={"Kebutuhan Faskes"}
							link={"/admin/perjal/kebutuhan"}
						/>
						<AdminCard
							Icon={BsCheckCircle}
							counter={data.terpasang}
							name={"Faskes Terpasang"}
							link={"/admin/perjal/terpasang"}
						/>
						<AdminCard
							Icon={IoWarningOutline}
							counter={data.priority}
							name={"Faskes Prioritas"}
							link={"/admin/perjal/prioritas"}
						/>
					</SimpleGrid>
				</Box>
			</Box>

			<Box
				width={"100%"}
				alignContent={"center"}
				justifyContent={"center"}
				mb={10}
			>
				<Text
					ml={5}
					mb={5}
					fontWeight={"semibold"}
					fontSize={"var(--header-3)"}
					color={"var(--color-primer)"}
				>
					Data Utama
				</Text>

				<Box
					display={"flex"}
					justifyContent={"center"}
					alignContent={"center"}
					alignItems={"center"}
					width={"100%"}
				>
					<SimpleGrid
						spacing={20}
						templateColumns={{
							base: "repeat(1, 1fr)",
							md: "repeat(3, 1fr)",
							xl: "repeat(5, 1fr)",
						}}
					>
						<AdminCard
							Icon={FaRoad}
							counter={data.RuasJalan}
							name={"Ruas Jalan"}
							link={"/admin/data/ruas-jalan"}
						/>
						<AdminCard
							Icon={MdCategory}
							counter={data.KategoriPerjal}
							name={"Kategori Faskes"}
							link={"/admin/data/kategori-perjal"}
						/>
						<AdminCard
							Icon={MdLabel}
							counter={data.NamaPerjal}
							name={"Nama Faskes"}
							link={"/admin/data/nama-perjal"}
						/>
						<AdminCard
							Icon={MdLocationOn}
							counter={data.Kabupaten}
							name={"Kabupaten"}
							link={"/admin/data/kabupaten"}
						/>
						<AdminCard
							Icon={FaCarCrash}
							counter={data.DRK}
							name={"Daerah Rawan Kecelakaan"}
							link={"/admin/data/drk"}
						/>
					</SimpleGrid>
				</Box>
			</Box>

			{/* <Text
				ml={5}
				fontWeight={"semibold"}
				fontSize={"var(--header-4)"}
				color={"var(--color-primer)"}
			>
				Dashboard
			</Text> */}
		</Box>
	)
}

export default Dashboard
