import React from "react"
import { MapContainer, TileLayer, Marker, Popup, CircleMarker } from "react-leaflet"
import { Image,Text } from "@chakra-ui/react"
import "leaflet/dist/leaflet.css" // Import the Leaflet CSS
import './map.css'; // Create a CSS file for your custom styles
import L, { map, marker } from 'leaflet';

const MapComponent = ({
  zoom,
  data,
  popup,
  from,
  selectedRows
}) => {
  const position = [-5.450000, 105.266670];
  const filteredDRK = data.drks && data.drks.length > 0
    ? data.drks.filter((drk) => selectedRows.includes(drk.id_kategori_perjal))
    : [];
  return (
    <MapContainer center={position} zoom={9} className="map-container">
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {
        from ==='kabupaten'?
        data.map((marker, index) => (
          <Marker
          key={index}
          position={[marker.latitude, marker.longitude]}
          icon= {new L.Icon({
            iconUrl: process.env.PUBLIC_URL + '/pin.png',
            iconSize: [50, 50],
            popupAnchor: [0, -18],
          })}
          >
          <Popup>
              <Text color={'black'} fontWeight={'semibold'}>
                {popup}{marker.nama_kabupaten}
              <Text color={'black'} fontWeight={'semibold'}>
                Jumlah Ruas Jalan : {marker.ruas_jalans[0]?.total_ruas_jalan ||0}
              </Text>
              </Text>
          </Popup>
      </Marker>
      ))
      :
      from === 'ruas'?
      data.map((marker,index)=>(
        <>
        <Marker
          key={index}
          position={[marker.start_latitude,marker.start_longitude]}
          icon= {new L.Icon({
            iconUrl: process.env.PUBLIC_URL + '/pin_start.png',
            iconSize: [50, 50],
            popupAnchor: [0, -18],
          })}
        >
          <Popup>
              <Text fontWeight={'bold'} color={'#55A153'}>
                Titik Awal Ruas
              </Text>
              <Text color={'black'} fontWeight={'semibold'}>
                {popup}{marker.nama_ruas_jalan}
              <Text color={'black'} fontWeight={'semibold'}>
                Panjang Ruas Jalan : {marker.panjang||0}
              </Text>
              </Text>
          </Popup>
        </Marker>
        <Marker
          key={index}
          position={[marker.end_latitude,marker.end_longitude]}
          icon= {new L.Icon({
            iconUrl: process.env.PUBLIC_URL + '/pin_end.png',
            iconSize: [50, 50],
            popupAnchor: [0, -18],
          })}
        >
          <Popup>
              <Text fontWeight={'bold'} color={'#A15353'}>
                Titik Akhir Ruas
              </Text>
              <Text color={'black'} fontWeight={'semibold'}>
                {popup}{marker.nama_ruas_jalan}
              <Text color={'black'} fontWeight={'semibold'}>
                Panjang Ruas Jalan : {marker.panjang||0}
              </Text>
              </Text>
          </Popup>
        </Marker>
        </>
      ))
      :
      from === 'perjal'?
      <>
      <Marker
        position={[
          data ? data.start_latitude : 0,
          data ? data.start_longitude : 0,
        ]}
        icon={new L.Icon({
          iconUrl: process.env.PUBLIC_URL + "/pin_start.png",
          iconSize: [50, 50],
          popupAnchor: [0, -18],
        })}
      >
        <Popup>
          <Text fontWeight={"bold"} color={"#55A153"}>
            Titik Awal Ruas
          </Text>
          <Text color={"black"} fontWeight={"semibold"}>
            {popup}
            {data ? data.nama_ruas_jalan : "Ruas jalan tidak diketahui"}
            <Text color={"black"} fontWeight={"semibold"}>
              Panjang Ruas Jalan : {data ? data.panjang : 0}
            </Text>
          </Text>
        </Popup>
      </Marker>
      <Marker
        position={[
          data ? data.end_latitude : 0,
          data ? data.end_longitude : 0,
        ]}
        icon={new L.Icon({
          iconUrl: process.env.PUBLIC_URL + "/pin_end.png",
          iconSize: [50, 50],
          popupAnchor: [0, -18],
        })}
      >
        <Popup>
          <Text fontWeight={"bold"} color={"#A15353"}>
            Titik Akhir Ruas
          </Text>
          <Text color={"black"} fontWeight={"semibold"}>
            {popup}
            {data ? data.nama_ruas_jalan : "Ruas jalan tidak diketahui"}
            <Text color={"black"} fontWeight={"semibold"}>
              Panjang Ruas Jalan : {data ? data.panjang : 0}
            </Text>
          </Text>
        </Popup>
      </Marker>
        {from === "perjal" && data && data.perjal_by_kategori && data.perjal_by_kategori.length > 0
  ? data.perjal_by_kategori.map((perjal, index) => {
      const isSelected = selectedRows.includes(perjal.id_kategori_perjal);
      return (
        <React.Fragment key={index}>
          {isSelected &&
            perjal.perjals.map((perjalItem, perjalIndex) => (
              <Marker
                key={`perjal-${index}-${perjalIndex}`}
                position={[
                  perjalItem.latitude || 0,
                  perjalItem.longitude || 0,
                ]}
                icon={new L.Icon({
                  iconUrl: `${perjalItem.nama_perjal.url_simbol_nama_perjal}`, // Set your custom icon URL
                  iconSize: [40, 40],
                  popupAnchor: [0, -30],
                })}
              >
                <Popup>
                  <Image src={perjalItem.url_foto_perjal} />
                  <Text fontWeight={"bold"} color={"#FF5733"}>
                    {perjal.nama_kategori_perjal}
                  </Text>
                  <Text color={"black"} fontWeight={"semibold"}>
                    {popup}{perjalItem.nama_perjal.nama_perjal}
                  </Text>
                </Popup>
              </Marker>
            ))}
        </React.Fragment>
      );
    })
  : null}
{filteredDRK.map((drk, index) => (
        // console.log(drk),
        drk.jenis_drk === "Area" ?
        <CircleMarker
          key={index}
          pathOptions={{ color: "red" }}
          center={[drk.latitude, drk.longitude]}
          radius={20}
        >
          <Popup>
            <Image src={drk.url_foto_drk}/>
            <Text>Area Rawan Kecelakaan</Text>
            <Text>
            Keterangan: {drk.keterangan}
            </Text>
          </Popup>
        </CircleMarker>
        :
        <Marker
          key={index}
          position={[drk.latitude, drk.longitude]}
          icon={new L.Icon({
            iconUrl: process.env.PUBLIC_URL + '/DRK.png', // Set your custom icon URL
            iconSize: [40, 40],
            popupAnchor: [0, -30],
          })}
        >
          <Popup>
            <Image src={drk.url_foto_drk}/>
            <Text>Titik Rawan Kecelakaan</Text>
            <Text>
            Keterangan: {drk.keterangan}
            </Text>
          </Popup>
        </Marker>
      ))}
    </>
      :
      <></>
      }
    </MapContainer>
  );
};

export default MapComponent
