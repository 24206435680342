import { Table, Tbody, Tr, Td, Box } from "@chakra-ui/react"

export const ExpandRuasJalan = (props) => {
	return (
		<Box p={5}>
			<Table size={"sm"} variant={"simple"} colorScheme="blue">
				{/* <Thead>
					<Tr>
						<Th>Label</Th>
						<Th>Value</Th>
					</Tr>
				</Thead> */}
				<Tbody>
					<Tr>
						<Td fontWeight={"bold"}>Nomor Ruas</Td>
						<Td>{props.data.id_ruas_jalan}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Nama Ruas Jalan</Td>
						<Td>{props.data.nama_ruas_jalan}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Panjang</Td>
						<Td>{props.data.panjang}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Status</Td>
						<Td>{props.data.status}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Fungsi</Td>
						<Td>{props.data.fungsi}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Remark</Td>
						<Td>{props.data.remark}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Kabupaten</Td>
						<Td>{props.data.kabupaten.nama_kabupaten}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Sumber</Td>
						<Td>{props.data.sumber}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Start Latitude</Td>
						<Td>{props.data.start_latitude}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Start Longitude</Td>
						<Td>{props.data.start_longitude}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>End Latitude</Td>
						<Td>{props.data.end_latitude}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>End Longitude</Td>
						<Td>{props.data.end_longitude}</Td>
					</Tr>
				</Tbody>
			</Table>
		</Box>
	)
}
