import {React,useState,useEffect} from 'react';
import { Flex,Wrap,Box,Text,Image, Divider } from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import MapComponent from '../../Components/Publik/Map/map';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import { APIKabupaten } from '../../Components/Admin/Utils/API/api';
import { TabTitle } from '../../Components/Admin/Utils/config';
const KebutuhanRuas = () => {
  TabTitle("Kebutuhan Ruas")
  const customStyles = {
    headCells: {
        style: {
            backgroundColor: "#0e1d34",
            color: "#ffffff",
        },
    },
  };
  const Columns = [
    {
      name: "#",
      selector: "id_kabupaten",
      sortable: true,
      width: "20%",
    },
    {
		name: "Nama Kabupaten",
		selector: "nama_kabupaten",
		sortable: true,
    width: "40%", 
    cell: (row) => (
      <div style={{ whiteSpace: 'normal', overflow: 'visible' }}>
        <Link className="text" to={`/kebutuhan_ruas/kabupaten/${row.id_kabupaten}`}>
          <Text fontSize="13px">{row.nama_kabupaten}</Text>
        </Link>
      </div>
    ),
	},
  {
		name: "Jumlah Ruas",
		selector: "jumlah_ruas",
		sortable: true,
	}
  ];

  const [dataKabupaten, setDataKabupaten] = useState([]);
  const [kodeKabupaten, setKodeKabupaten] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(APIKabupaten.show).then((res) => {
        setDataKabupaten(res.data);
        });
    } catch (error) {
    }
  };
  useEffect(() => {
		fetchData()
	}, [])
  return (
    <>
    <Box
        width={'100%'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        minHeight="100vh" 
        overflowY="hidden">
          <Box bgGradient='linear(to-b, #E9FCFE, #FAE4ED)' 
        width={'100%'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        pb={'2%'}
        minHeight="100vh">
           <Flex flexDir={{'base':'column','md':'row'}}  justifyContent={'flex-start'} width={'90%'}>
          <Wrap display={{'base': 'flex'}} mt={{'base':'40%','md':'20%','lg':'12%'}} alignContent={'flex-start'} textAlign={'left'} alignItems={'flex-start'} alignSelf={'flex-start'} width={{'base':'80%','md':'90%'}}>
            <Text color={'#0e1d34'} mt={{'base':'35%','sm':'0%','md':'0%'}} fontFamily={'inter'} fontSize={{'base':'30px','md':'48px'}} fontWeight={'bold'}>
              Kebutuhan Ruas
            </Text>
            <Text display={{'base':'none','md':'flex'}} color={'#0e1d34'} fontFamily={'inter'} fontSize={'16px'}>
            Berikut merupakan ruas kebutuhan terkait perlengkapan jalan meliputi APIL, alat penerangan jalan, alat pengendali dan pengaman pengguna jalan, marka jalan dan rambu lalu lintas di Provinsi Lampung.
            </Text>
          </Wrap>
          <Flex ml={{'base':'0%','md':'10%'}} display={{'base': 'flex', }} justify={'center'} mt={{'base':'-80%','md':'20%','lg':'0%'}} alignContent={'flex-start'} textAlign={'left'} alignItems={'flex-start'} alignSelf={'flex-start'} width={{'base':'100%','md':'80%'}}>
            <Image height={'fit-content'} width={{'base':'70%','md':'80%'}} src={process.env.PUBLIC_URL + '/kebutuhan_perjal.png'} />
          </Flex>
          </Flex>
          <Flex width={'90%'} mt={{'base':'15%', 'md':'0%', 'lg':'1%'}}>
            <Box
              width={'100%'}
              display="flex"
              bgColor={'#0e1d34'}
              height={'100%'}
              flexDirection="column"
              borderRadius={'10px'}
              boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
              >
               <Flex justify={'center'} alignContent={'center'} alignItems={'center'} ml={'10px'} mt={'10px'} borderTopRadius={'10px'} bg='white' h={'5%'} width={{'base':'40%','md':'20%','lg':'14%'}}>
                <Text color={'#495057'} mt={'2%'} ml={'2%'} fontFamily={'sans-serif'} fontSize={{'base':'16px','md':'16px'}} fontWeight={'bold'}>
                  Jalan Provinsi
                </Text>
              </Flex>
               <Flex flexDir={{'base':'column','md':'row'}} width={'100%'} py={'1%'} height={'100%'} bgColor={'white'}>
                <Flex flexDir={{'base':'column','md':'column'}} width={{'base':'100%','md':'35%'}} mx={{'base':'0%','md':'2%'}}>
                <Text color={'#495057'} mx='10px' fontSize={'16px'} fontWeight={'semibold'}>
                  Provinsi Lampung
                </Text>
                <Text color={'#495057'} mx='10px' fontSize={'16px'} fontWeight={'semibold'}>
                  Kabupaten/Kota
                </Text>
                <Divider my={'3%'} width={'99%'} border={'0.6px solid'} opacity={'0.25'}/>
                <Box 
                  maxWidth={"calc(100vw - 2rem)"}
                  width={"100%"}
                  height={"100%"}
                  margin={{
                    base: "0rem",
                    md: "0rem",
                  }}
                >
                 <DataTable 
              columns={Columns}
              data={dataKabupaten.map(kabupaten => ({
                ...kabupaten,
                id_kabupaten: kabupaten.id_kabupaten,
                nama_kabupaten:kabupaten.nama_kabupaten,
                jumlah_ruas:kabupaten.ruas_jalans[0]?.total_ruas_jalan || 0,
              }))}
              customStyles={customStyles}
              striped
              highlightOnHover
              pointerOnHove
              responsive
              noDataComponent={<Text>Tidak ada data</Text>}
              />
                </Box>
                </Flex >
                <Flex p={'10px'} width={'100%'}  alignContent={'center'} alignItems={'center'} justifyContent={'center'}>
                  <MapComponent 
                    zoom={8}
                    data={dataKabupaten}
                    from={'kabupaten'}
                    popup={'Kabupaten/Kota : '}
                  />
                </Flex>
              </Flex>  
              </Box>
          </Flex>  
          </Box>
    </Box>
    </>
  );
}
export default KebutuhanRuas;