import React, { useEffect } from "react"
import { Flex, Text, Icon, useDisclosure, Box, Button } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { IoExitOutline } from "react-icons/io5"
import { AiOutlineMenu } from "react-icons/ai"
import { useSelector } from "react-redux"
import { logoutUser } from "../../../Publik/Util/api"
import axios from "axios"
import Cookies from "js-cookie"
import { useDispatch } from "react-redux"
import { routePageName } from "../../../../Components/Admin/Utils/Redux/action"

import Drawer from "../Drawer/drawer"
import PopupLogout from "../../Table/Popup/Logout/logout"

const NavBar = () => {
	const dispatch = useDispatch()
	const cancelRef = React.useRef()

	const { routeName } = useSelector((state) => state.userReducer)
	const navigate = useNavigate()
	const logout = async () => {
		try {
			await axios
				.delete(logoutUser, {
					withCredentials: true,
				})
				.then(() => {
					dispatch(routePageName("Dashboard"))
					navigate("/login")
				})
		} catch (error) {
			console.error("Logout error:", error)
		}
	}

	useEffect(() => {}, [])

	const { isOpen, onOpen, onClose } = useDisclosure()
	const stateLogout = useDisclosure()

	return (
		<Flex
			height={"80px"}
			bg={"#ffff"}
			padding={"20px"}
			justifyContent="space-between"
			alignItems={"center"}
			flexDirection={"row"}
			boxShadow="2px 2px 3px 0 rgba(0, 0, 0, 0.05)"
		>
			<PopupLogout
				isOpen={stateLogout.isOpen}
				onClose={stateLogout.onClose}
				cancelRef={cancelRef}
			/>
			<Button
				onClick={onOpen}
				display={{
					lg: "none",
				}}
				variant={"ghost"}
				size={{
					base: "sm",
					sm: "sm",
					lg: "md",
					xl: "md",
				}}
			>
				<Icon
					as={AiOutlineMenu}
					fontSize="xl"
					color={"#09322D"}
					display={{
						lg: "none",
					}}
				/>
			</Button>

			<Drawer
				data={{
					onclose: onClose,
					isopen: isOpen,
				}}
			/>

			<Text
				color={"var(--color-primer)"}
				fontWeight={"bold"}
				fontSize={{
					base: "var(--headerNav3)",
					sm: "var(--headerNav2)",
					lg: "var(--headerNav1)",
				}}
			>
				{routeName}
			</Text>

			<Flex flexDirection={"row"}>
				<Button
					variant={"ghost"}
					onClick={stateLogout.onOpen}
					size={{
						base: "sm",
						sm: "sm",
						lg: "md",
						xl: "md",
					}}
				>
					<Box
						display={"flex"}
						justifyContent={"center"}
						alignItems={"center"}
					>
						<Icon
							cursor="pointer"
							as={IoExitOutline}
							color={"var(--color-primer)"}
							fontSize={"var(--icon-1)"}
							mr={"5px"}
						/>
						<Text
							fontSize={{
								base: "var(--text-1)",
								sm: "var(--text-1)",
								lg: "var(--text-1)",
								xl: "var(--text-1)",
							}}
							fontWeight={"regular"}
							fontFamily={"var(--font-family-secondary)"}
							color={"#000000"}
							display={{
								base: "none",
								lg: "block",
							}}
						>
							Keluar
						</Text>
					</Box>
				</Button>
			</Flex>
		</Flex>
	)
}
export default NavBar
