import React from "react"
import {
	Image,
	Modal,
	ModalOverlay,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalBody,
	Flex,
	Text,
} from "@chakra-ui/react"

const PopupImage = ({ isOpen, onClose, header, image, alt, caption }) => {
	const customModalSize = {
		maxWidth: "2000px",
		width: "80%",
	}

	return (
		<Modal
			isCentered
			size={"xl"}
			isOpen={isOpen}
			onClose={onClose}
			width={"200vh"}
			maxWidth={"200vh"}
		>
			<ModalOverlay />
			{/* <ModalCloseButton /> */}
			<ModalContent
				// className="custom-modal-content"
				style={customModalSize}
			>
				<ModalHeader>{header}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Flex
						width={"100%"}
						height={"100%"}
						alignContent={"center"}
						justifyContent={"center"}
						flexDirection={"column"}
					>
						<Flex
							width={"100%"}
							height={"100%"}
							alignContent={"center"}
							justifyContent={"center"}
						>
							<Image src={image} alt={alt} />
						</Flex>
						<Flex width={"100%"}>
							<Text mt={5} fontWeight={"bold"}>
								Keterangan : {caption}
							</Text>
						</Flex>
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default PopupImage
