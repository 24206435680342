import React, { useEffect, useState } from "react"
import axios from "axios"
import { useDispatch } from "react-redux"
import { Image } from "@chakra-ui/react"

import Action from "../../../../Components/Admin/Table/Action/action"
import { routePageName } from "../../../../Components/Admin/Utils/Redux/action"
import { TabTitle } from "../../../../Components/Admin/Utils/config"
import ContainerBox from "../../../../Components/Admin/Table/Container/container"

import FormDRK from "./formdrk"
import {
	APIDRK,
	APIRuasJalan,
} from "../../../../Components/Admin/Utils/API/api"

import { ExpandDRK } from "./expanddrk"
import useAuth from "../../../../middleware/useAuth"
import getUserLogin from "../../../../middleware/getUserLogin"

const HandlerFilter = (data, e) => {
	const keyword = e.target.value
	if (keyword !== "") {
		const results = data.filter((data) => {
			return data.ruas_jalan.nama_ruas_jalan
				.toLowerCase()
				.includes(keyword.toLowerCase())
		})
		return results
	} else {
		return data
	}
}

const API = APIDRK
const API2 = APIRuasJalan
const name = "Daerah Rawan Kecelakaan"
const Form = FormDRK

const PageDRK = () => {
	useAuth()

	const [user, setUser] = useState()
	const linkCreate = API.create
	const filter = HandlerFilter

	TabTitle(name)
	const [data, setData] = useState()
	const [dataCategory, setDataCategory] = useState()

	const dispatch = useDispatch()
	const patchRoute = (data) => {
		dispatch(routePageName(data))
	}

	const Columns = [
		{
			name: "No",
			selector: "id_drk",
			sortable: true,
			width: "100px",
			cell: (row, index) => {
				return <span>{index + 1}</span>
			},
		},
		{
			name: "Nama Ruas Jalan",
			selector: "ruas_jalan.nama_ruas_jalan",
			sortable: true,
		},
		// {
		// 	name: "Foto",
		// 	selector: "foto_drk",
		// 	sortable: true,
		// 	cell: (row) => (
		// 		<Image
		// 			src={row.foto_drk}
		// 			alt={row.nama_ruas_jalan}
		// 			width="100px"
		// 			height="100px"
		// 		/>
		// 	),
		// },
		{
			name: "Latitude",
			selector: "latitude",
			sortable: true,
		},
		{
			name: "Longitude",
			selector: "longitude",
			sortable: true,
		},

		user === "super admin"
			? {
					name: "Aksi",
					center: true,
					width: "240px",
					cell: (row) => (
						<Action
							id={row.id_drk}
							nameSelected={row.ruas_jalan.nama_ruas_jalan}
							APIEdit={API.edit}
							APIDelete={API.delete}
							row={row}
							Edit={Form}
							name={name}
							category={dataCategory}
							fetchData={fetchData}
						/>
					),
			  }
			: {
					width: "0px",
			  },
	]

	const fetchData = async () => {
		try {
			axios.get(API.show).then((response) => {
				setData(response.data)
			})
		} catch (error) {
			console.log(error)
		}

		try {
			axios.get(API2.show).then((response) => {
				setDataCategory(response.data)
			})
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		fetchData()
		patchRoute(name)
		getUserLogin().then((res) => {
			setUser(res.role)
		})
	}, [])

	return (
		<ContainerBox
			user={user}
			Create={Form}
			name={name}
			records={data}
			columns={Columns}
			filter={filter}
			linkCreate={linkCreate}
			expand={true}
			ComponentExpanded={ExpandDRK}
			category={dataCategory}
			fetchData={fetchData}
		/>
	)
}

export default PageDRK
