import React from "react"
import * as Yup from "yup"
import {
	VStack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useToast,
	Flex,
	Button,
} from "@chakra-ui/react"
import { Formik } from "formik"
import { FormInput } from "../../../../Components/Admin/Table/Form/input"
import {
	ButtonDelete,
	ButtonEdit,
} from "../../../../Components/Admin/Table/Button/button"
import axios from "axios"

const FormKabupaten = ({
	name,
	exist,
	isOpen,
	onClose,
	initialRef,
	finalRef,
	row,
	linkEdit,
	linkCreate,
	fetchData,
}) => {
	const toast = useToast()

	return (
		<Modal
			initialFocusRef={initialRef}
			finalFocusRef={finalRef}
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				{exist ? (
					<ModalHeader>Edit {name}</ModalHeader>
				) : (
					<ModalHeader>Tambah {name}</ModalHeader>
				)}
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Formik
						initialValues={
							exist
								? {
										id_kabupaten: row.id_kabupaten,
										nama_kabupaten: row.nama_kabupaten,
										latitude: row.latitude,
										longitude: row.longitude,
								  }
								: {
										id_kabupaten: "",
										nama_kabupaten: "",
										latitude: "",
										longitude: "",
								  }
						}
						validationSchema={Yup.object({
							id_kabupaten: Yup.string().required("Wajib diisi"),
							nama_kabupaten:
								Yup.string().required("Wajib diisi"),
							latitude: Yup.string().required("Wajib diisi"),
							longitude: Yup.string().required("Wajib diisi"),
						})}
						onSubmit={(values) => {
							if (exist) {
								const submitted = {
									id_kabupaten: values.id_kabupaten,
									nama_kabupaten: values.nama_kabupaten,
									latitude: values.latitude,
									longitude: values.longitude,
								}

								try {
									const res = async () => {
										const response = await axios.patch(
											linkEdit,
											submitted
										)

										return response
									}
									res().then((response) => {
										if (response.status === 201) {
											toast({
												title: "Berhasil",
												description:
													"Data berhasil diubah",
												status: "success",
												duration: 3000,
												isClosable: true,
											})
											fetchData()
											onClose()
										}
									})
								} catch (error) {
									toast({
										title: "Gagal",
										description: "Data gagal diubah",
										status: "error",
										duration: 3000,
										isClosable: true,
									})
									console.log(error)
								}
							} else {
								const submitted = {
									id_kabupaten: values.id_kabupaten,
									nama_kabupaten: values.nama_kabupaten,
									latitude: values.latitude,
									longitude: values.longitude,
								}

								try {
									const res = async () => {
										const response = await axios.post(
											linkCreate,
											submitted
										)

										return response
									}
									res().then((response) => {
										if (response.status === 201) {
											toast({
												title: "Berhasil",
												description:
													"Data berhasil ditambahkan",
												status: "success",
												duration: 3000,
												isClosable: true,
											})
											fetchData()
											onClose()
										}
									})
								} catch (error) {
									console.log(error)
								}
							}
						}}
					>
						{({ handleSubmit, errors, touched, setFieldValue }) => (
							<form onSubmit={handleSubmit}>
								<VStack spacing={2} align="flex-start">
									<FormInput
										id="id_kabupaten"
										name="Kode Kabupaten"
										errors={errors.id_kabupaten}
										touched={touched.id_kabupaten}
									/>
									<FormInput
										id="nama_kabupaten"
										name="Nama Kabupaten"
										errors={errors.nama_kabupaten}
										touched={touched.nama_kabupaten}
									/>
									<Flex>
										<FormInput
											id="latitude"
											name="Latitude"
											errors={errors.latitude}
											touched={touched.latitude}
										/>
										<FormInput
											id="longitude"
											name="Longitude"
											errors={errors.longitude}
											touched={touched.longitude}
										/>
									</Flex>
									<Button
										size={"sm"}
										colorScheme="orange"
										width={"100%"}
										mb={5}
										variant={"outline"}
										onClick={() => {
											//get current position
											navigator.geolocation.getCurrentPosition(
												(position) => {
													setFieldValue(
														"latitude",
														position.coords.latitude
													)
													setFieldValue(
														"longitude",
														position.coords
															.longitude
													)
												}
											)
										}}
									>
										Generate End Latitude & Longitude
									</Button>

									{exist ? <ButtonEdit /> : <ButtonDelete />}
								</VStack>
							</form>
						)}
					</Formik>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default FormKabupaten
