import { Button } from "@chakra-ui/react"

export const ButtonEdit = () => {
	return (
		<Button colorScheme="yellow" size="sm" type="submit" width="full">
			Simpan
		</Button>
	)
}

export const ButtonDelete = () => {
	return (
		<Button colorScheme="blue" size="sm" type="submit" width="full">
			Tambah
		</Button>
	)
}
