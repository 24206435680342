import React, { useState, useEffect } from "react"
import { Box, Collapse } from "@chakra-ui/react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import { AiFillHome, AiFillDatabase } from "react-icons/ai"
import { BsCheckCircle, BsInfoCircle } from "react-icons/bs"
import { IoWarningOutline } from "react-icons/io5"
import { RiDiscussFill } from "react-icons/ri"
import { MdLocationOn, MdLabel, MdCategory, MdSecurity } from "react-icons/md"
import { FaCarCrash, FaRoad } from "react-icons/fa"

import { routePageName } from "../../Utils/Redux/action"
import NavItem from "../NavItem/navitem"
import Section from "../Section/section"
import getUserLogin from "../../../../middleware/getUserLogin"

const SidebarMenu = () => {
	const navSize = "large"

	const [user, setUser] = useState()
	const dispatch = useDispatch()

	const patchRoute = (data) => {
		dispatch(routePageName(data))
	}

	const { routeName } = useSelector((state) => state.userReducer)

	const [subnav, setSubnav] = useState(false)
	const [subnav2, setSubnav2] = useState(false)

	useEffect(() => {
		getUserLogin().then((res) => {
			setUser(res.role)
		})

		if (
			routeName === "Perjal Kebutuhan" ||
			routeName === "Perjal Terpasang" ||
			routeName === "Perjal Prioritas"
		) {
			setSubnav(true)
		}
		if (
			routeName === "Ruas Jalan" ||
			routeName === "Kategori Perjal" ||
			routeName === "Nama Perjal" ||
			routeName === "Kabupaten" ||
			routeName === "Daerah Rawan Kecelakaan"
		) {
			setSubnav2(true)
		}
	}, [routeName])

	return (
		<Box>
			<Link
				to={"dashboard"}
				onClick={() => {
					patchRoute("Dashboard")
				}}
			>
				<NavItem
					navSize={navSize}
					icon={AiFillHome}
					title="Dashboard"
					active={routeName === "Dashboard"}
					width={"95%"}
				/>
			</Link>

			<Box>
				<Link
					onClick={() => {
						setSubnav(!subnav)
					}}
				>
					{subnav ? (
						<Section
							navSize={navSize}
							icon={FaRoad}
							title="Prasarana Jalan (Perjal)"
							variant="up"
						/>
					) : (
						<Section
							navSize={navSize}
							icon={FaRoad}
							title="Prasarana Jalan (Perjal)"
							variant="down"
						/>
					)}
				</Link>

				<Collapse in={subnav} animateOpacity>
					<Box>
						<Link
							to={"perjal/kebutuhan"}
							onClick={() => {
								patchRoute("Perjal Kebutuhan")
							}}
						>
							<NavItem
								navSize={navSize}
								icon={BsInfoCircle}
								title="Kebutuhan"
								active={routeName === "Perjal Kebutuhan"}
								width={"85%"}
							/>
						</Link>
						<Link
							to={"perjal/terpasang"}
							onClick={() => {
								patchRoute("Perjal Terpasang")
							}}
						>
							<NavItem
								navSize={navSize}
								icon={BsCheckCircle}
								title="Terpasang"
								active={routeName === "Perjal Terpasang"}
								width={"85%"}
							/>
						</Link>
						<Link
							to={"perjal/prioritas"}
							onClick={() => {
								patchRoute("Perjal Prioritas")
							}}
						>
							<NavItem
								navSize={navSize}
								icon={IoWarningOutline}
								title="Prioritas"
								active={routeName === "Perjal Prioritas"}
								width={"85%"}
							/>
						</Link>
					</Box>
				</Collapse>
			</Box>

			<Box>
				<Link
					onClick={() => {
						setSubnav2(!subnav2)
					}}
				>
					{subnav2 ? (
						<Section
							navSize={navSize}
							icon={AiFillDatabase}
							title="Data Utama"
							variant="up"
						/>
					) : (
						<Section
							navSize={navSize}
							icon={AiFillDatabase}
							title="Data Utama"
							variant="down"
						/>
					)}
				</Link>

				<Collapse in={subnav2} animateOpacity>
					<Box>
						<Link
							to={"data/ruas-jalan"}
							onClick={() => {
								patchRoute("Ruas Jalan")
							}}
						>
							<NavItem
								navSize={navSize}
								icon={FaRoad}
								title="Ruas Jalan"
								active={routeName === "Ruas Jalan"}
								width={"85%"}
							/>
						</Link>
						<Link
							to={"data/kategori-perjal"}
							onClick={() => {
								patchRoute("Kategori Perjal")
							}}
						>
							<NavItem
								navSize={navSize}
								icon={MdCategory}
								title="Kategori Perjal"
								active={routeName === "Kategori Perjal"}
								width={"85%"}
							/>
						</Link>
						<Link
							to={"data/nama-perjal"}
							onClick={() => {
								patchRoute("Nama Perjal")
							}}
						>
							<NavItem
								navSize={navSize}
								icon={MdLabel}
								title="Nama Perjal"
								active={routeName === "Nama Perjal"}
								width={"85%"}
							/>
						</Link>
						<Link
							to={"data/kabupaten"}
							onClick={() => {
								patchRoute("Kabupaten")
							}}
						>
							<NavItem
								navSize={navSize}
								icon={MdLocationOn}
								title="Kabupaten"
								active={routeName === "Kabupaten"}
								width={"85%"}
							/>
						</Link>
						<Link
							to={"data/drk"}
							onClick={() => {
								patchRoute("Daerah Rawan Kecelakaan")
							}}
						>
							<NavItem
								navSize={navSize}
								icon={FaCarCrash}
								title="Daerah Rawan Kecelakaan"
								active={routeName === "Daerah Rawan Kecelakaan"}
								width={"85%"}
							/>
						</Link>
					</Box>
				</Collapse>
			</Box>

			<Box>
				<Link
					to={"data/saran-aduan"}
					onClick={() => {
						patchRoute("Saran Dan Aduan")
					}}
				>
					<NavItem
						navSize={navSize}
						icon={RiDiscussFill}
						title="Saran Dan Aduan"
						active={routeName === "Saran Dan Aduan"}
						width={"95%"}
					/>
				</Link>
			</Box>
			{user == "super admin" ? (
				<Box mb={10}>
					<Link
						to={"data/akses-pengguna"}
						onClick={() => {
							patchRoute("Akses Pengguna")
						}}
					>
						<NavItem
							navSize={navSize}
							icon={MdSecurity}
							title="Akses Pengguna"
							active={routeName === "Akses Pengguna"}
							width={"95%"}
						/>
					</Link>
				</Box>
			) : (
				<></>
			)}
		</Box>
	)
}

export default SidebarMenu
