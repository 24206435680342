import React, { useEffect, useState } from "react"
import axios from "axios"
import { useDispatch } from "react-redux"
import { Text } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

import Action from "../../../Components/Admin/Table/Action/action"
import { routePageName } from "../../../Components/Admin/Utils/Redux/action"
import { TabTitle } from "../../../Components/Admin/Utils/config"
import ContainerBox from "../../../Components/Admin/Table/Container/container"

import FormAkses from "./formakses"
import { APIAdmin } from "../../../Components/Admin/Utils/API/api"

import useAuth from "../../../middleware/useAuth"
import getUserLogin from "../../../middleware/getUserLogin"

const HandlerFilter = (data, e) => {
	const keyword = e.target.value
	if (keyword !== "") {
		const results = data.filter((data) => {
			return (
				data.username.toLowerCase().includes(keyword.toLowerCase()) ||
				data.role.toLowerCase().includes(keyword.toLowerCase())
			)
		})
		return results
	} else {
		return data
	}
}

const API = APIAdmin
const name = "Akses Pengguna"
const Form = FormAkses

const PageAkses = () => {
	useAuth()

	const navigate = useNavigate()

	const [user, setUser] = useState()
	const linkCreate = API.create
	const filter = HandlerFilter

	TabTitle(name)
	const [data, setData] = useState()

	const dispatch = useDispatch()
	const patchRoute = (data) => {
		dispatch(routePageName(data))
	}

	const Columns = [
		{
			name: "No",
			selector: "id_users",
			sortable: true,
			width: "100px",
			cell: (row, index) => {
				return <span>{index + 1}</span>
			},
		},
		{
			name: "Username",
			selector: "username",
			sortable: true,
		},
		{
			name: "Role",
			selector: "role",
			sortable: true,
		},

		user === "super admin"
			? {
					name: "Aksi",
					center: true,
					width: "240px",
					cell: (row) => (
						<Action
							id={row.id_users}
							nameSelected={row.username}
							APIEdit={API.edit}
							APIDelete={API.delete}
							row={row}
							Edit={Form}
							name={name}
							fetchData={fetchData}
						/>
					),
			  }
			: {
					width: "0px",
			  },
	]

	const fetchData = async () => {
		try {
			axios
				.get(API.show, {
					withCredentials: true,
				})
				.then((response) => {
					// console.log(response.data)
					setData(response.data)
				})
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		patchRoute(name)
		getUserLogin().then((res) => {
			if (res.role == "admin") {
				patchRoute("Dashboard")
				navigate("/admin/dashboard")
			} else {
				fetchData()
				setUser(res.role)
			}
		})
	}, [])

	return (
		<ContainerBox
			user={user}
			Create={Form}
			name={name}
			records={data}
			columns={Columns}
			filter={filter}
			linkCreate={linkCreate}
			fetchData={fetchData}
		/>
	)
}

export default PageAkses
