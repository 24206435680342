import React from "react"
import {
	FormControl,
	FormLabel,
	Select,
	FormErrorMessage,
} from "@chakra-ui/react"
import { Field } from "formik"

export const FormInputSelectedAktif = ({
	id,
	name,
	errors,
	touched,
	value,
}) => {
	return (
		<FormControl isInvalid={!!errors && touched}>
			<FormLabel htmlFor={id}>{name}</FormLabel>

			<Field
				as={Select}
				id={id}
				name={id}
				type={id}
				placeholder="Pilih Status"
			>
				<option value={true}>Aktif</option>
				<option value={false}>Tidak Aktif</option>
			</Field>
			<FormErrorMessage>{errors}</FormErrorMessage>
		</FormControl>
	)
}

export const FormInputSelected = ({
	id,
	name,
	errors,
	touched,
	options,
	placeholder,
}) => {
	return (
		<FormControl isInvalid={!!errors && touched}>
			<FormLabel htmlFor={id}>{name}</FormLabel>
			<Field
				as={Select}
				id={id}
				name={id}
				type={id}
				placeholder={placeholder}
			>
				{options.map((option) => (
					<option value={option.value}>{option.label}</option>
				))}
			</Field>
			<FormErrorMessage>{errors}</FormErrorMessage>
		</FormControl>
	)
}

export const FormInputSelectedChange = ({
	id,
	name,
	errors,
	touched,
	options,
	onChange,
}) => {
	return (
		<FormControl isInvalid={!!errors && touched}>
			<FormLabel htmlFor={id}>{name}</FormLabel>
			<Field as={Select} id={id} name={id} type={id} onChange={onChange}>
				{options.map((option) => (
					<option value={option.value}>{option.label}</option>
				))}
			</Field>
			<FormErrorMessage>{errors}</FormErrorMessage>
		</FormControl>
	)
}
