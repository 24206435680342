export const dataRuasJalan = [
	{
		id_ruas_jalan: "001.11.K",
		nama_ruas_jalan: "KALIANDA - KUNYIR - GAYAM",
		panjang: 25.43,
		status: "Jalan Nasional",
		fungsi: "JSP",
		remark: "Jalan Kolektor",
		kabupaten: "Lampung Selatan",
		sumber: "Keputusan Gubernur Lampung No: G/52/III.09/HK/2016",
		start_latitude: "-5.661",
		start_longitude: "105.633",
		end_latitude: "-5.661",
		end_longitude: "105.633",
	},
	{
		id_ruas_jalan: "002",
		nama_ruas_jalan: "GAYAM - KETAPANG",
		panjang: 11.43,
		status: "Jalan Provinsi",
		fungsi: "JSP-3",
		remark: "Jalan Lokal",
		kabupaten: "Lampung Selatan",
		sumber: "Keputusan Gubernur Lampung No: G/52/III.09/HK/2016",
		start_latitude: "-5.661",
		start_longitude: "105.633",
		end_latitude: "-5.661",
		end_longitude: "105.633",
	},
	{
		id_ruas_jalan: "003",
		nama_ruas_jalan: "JALAN AHMAD YANI (METRO)",
		panjang: 2.43,
		status: "Jalan Kota",
		fungsi: "JSP-3",
		remark: "Jalan Lokal",
		kabupaten: "Kota Metro",
		sumber: "Keputusan Gubernur Lampung No: G/52/III.09/HK/2016",
		start_latitude: "-5.661",
		start_longitude: "105.633",
		end_latitude: "-5.661",
		end_longitude: "105.633",
	},
	{
		id_ruas_jalan: "004",
		nama_ruas_jalan: "JALAN JENDRAL SUDIRMAN (METRO)",
		panjang: 2.43,
		status: "Jalan Kota",
		fungsi: "JSP-3",
		remark: "Jalan Lokal",
		kabupaten: "Kota Metro",
		sumber: "Keputusan Gubernur Lampung No: G/52/III.09/HK/2016",
		start_latitude: "-5.661",
		start_longitude: "105.633",
		end_latitude: "-5.661",
		end_longitude: "105.633",
	},
	{
		id_ruas_jalan: "001",
		nama_ruas_jalan: "KALIANDA - KUNYIR - GAYAM",
		panjang: 25.43,
		status: "Jalan Nasional",
		fungsi: "JSP",
		remark: "Jalan Kolektor",
		kabupaten: "Lampung Selatan",
		sumber: "Keputusan Gubernur Lampung No: G/52/III.09/HK/2016",
		start_latitude: "-5.661",
		start_longitude: "105.633",
		end_latitude: "-5.661",
		end_longitude: "105.633",
	},
	{
		id_ruas_jalan: "002",
		nama_ruas_jalan: "GAYAM - KETAPANG",
		panjang: 11.43,
		status: "Jalan Provinsi",
		fungsi: "JSP-3",
		remark: "Jalan Lokal",
		kabupaten: "Lampung Selatan",
		sumber: "Keputusan Gubernur Lampung No: G/52/III.09/HK/2016",
		start_latitude: "-5.661",
		start_longitude: "105.633",
		end_latitude: "-5.661",
		end_longitude: "105.633",
	},
	{
		id_ruas_jalan: "004",
		nama_ruas_jalan: "JALAN JENDRAL SUDIRMAN (METRO)",
		panjang: 2.43,
		status: "Jalan Kota",
		fungsi: "JSP-3",
		remark: "Jalan Lokal",
		kabupaten: "Kota Metro",
		sumber: "Keputusan Gubernur Lampung No: G/52/III.09/HK/2016",
		start_latitude: "-5.661",
		start_longitude: "105.633",
		end_latitude: "-5.661",
		end_longitude: "105.633",
	},
	{
		id_ruas_jalan: "001",
		nama_ruas_jalan: "KALIANDA - KUNYIR - GAYAM",
		panjang: 25.43,
		status: "Jalan Nasional",
		fungsi: "JSP",
		remark: "Jalan Kolektor",
		kabupaten: "Lampung Selatan",
		sumber: "Keputusan Gubernur Lampung No: G/52/III.09/HK/2016",
		start_latitude: "-5.661",
		start_longitude: "105.633",
		end_latitude: "-5.661",
		end_longitude: "105.633",
	},
	{
		id_ruas_jalan: "002",
		nama_ruas_jalan: "GAYAM - KETAPANG",
		panjang: 11.43,
		status: "Jalan Provinsi",
		fungsi: "JSP-3",
		remark: "Jalan Lokal",
		kabupaten: "Lampung Selatan",
		sumber: "Keputusan Gubernur Lampung No: G/52/III.09/HK/2016",
		start_latitude: "-5.661",
		start_longitude: "105.633",
		end_latitude: "-5.661",
		end_longitude: "105.633",
	},
	{
		id_ruas_jalan: "003",
		nama_ruas_jalan: "JALAN AHMAD YANI (METRO)",
		panjang: 2.43,
		status: "Jalan Kota",
		fungsi: "JSP-3",
		remark: "Jalan Lokal",
		kabupaten: "Kota Metro",
		sumber: "Keputusan Gubernur Lampung No: G/52/III.09/HK/2016",
		start_latitude: "-5.661",
		start_longitude: "105.633",
		end_latitude: "-5.661",
		end_longitude: "105.633",
	},
	{
		id_ruas_jalan: "004",
		nama_ruas_jalan: "JALAN JENDRAL SUDIRMAN (METRO)",
		panjang: 2.43,
		status: "Jalan Kota",
		fungsi: "JSP-3",
		remark: "Jalan Lokal",
		kabupaten: "Kota Metro",
		sumber: "Keputusan Gubernur Lampung No: G/52/III.09/HK/2016",
		start_latitude: "-5.661",
		start_longitude: "105.633",
		end_latitude: "-5.661",
		end_longitude: "105.633",
	},
	{
		id_ruas_jalan: "001",
		nama_ruas_jalan: "KALIANDA - KUNYIR - GAYAM",
		panjang: 25.43,
		status: "Jalan Nasional",
		fungsi: "JSP",
		remark: "Jalan Kolektor",
		kabupaten: "Lampung Selatan",
		sumber: "Keputusan Gubernur Lampung No: G/52/III.09/HK/2016",
		start_latitude: "-5.661",
		start_longitude: "105.633",
		end_latitude: "-5.661",
		end_longitude: "105.633",
	},
]
