import { NavLink, Outlet } from "react-router-dom"
import {
	Flex,
	Text,
	Image,
	Drawer,
	DrawerBody,
	Icon,
	Button,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
} from "@chakra-ui/react"
import { useDisclosure } from "@chakra-ui/hooks"
import { React, useEffect, useState } from "react"
import { GiHamburgerMenu } from "react-icons/gi"
import "./navbar.css"
import { useSelector, useDispatch } from "react-redux"
import { routePageName } from "../Redux/action"

const Navbar = () => {
	const dispatch = useDispatch()

	const patchRoute = (data) => {
		dispatch(routePageName(data))
	}
	const { routeName } = useSelector((state) => state.userReducer)
	const { isOpen, onOpen, onClose } = useDisclosure()
	// const btnRef = React.useRef()
	const [showBoxShadow, setShowBoxShadow] = useState(false)

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0 && !showBoxShadow) {
				setShowBoxShadow(true)
			} else if (window.scrollY === 0 && showBoxShadow) {
				setShowBoxShadow(false)
			}
		}

		window.addEventListener("scroll", handleScroll)

		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [showBoxShadow])

	return (
		<>
			<Flex
				align={"center"}
				py={10}
				height={"12vh"}
				position={"fixed"}
				zIndex={"997"}
				bg={showBoxShadow ? "#DBFEFF" : "none"}
				blur={4}
				width={"100vw"}
				maxWidth={"100vw"}
				justify={"space-between"}
				alignItems={"center"}
				flexDir={"row"}
				alignContent={"center"}
				pl={"2%"}
				pr={"2%"}
				display={{ base: "flex", md: "flex", lg: "none" }}
			>
				<Image
					maxH={"65px"}
					height={"60px"}
					mt={"2px"}
					src={process.env.PUBLIC_URL + "/logodishub.png"}
					maxW={"100%"}
				/>
				<Flex float={"right"} display={{ base: "flex" }}>
					<Button
						alignItems={"center"}
						alignContent={"center"}
						alignSelf={"center"}
						textAlign={"center"}
						bgColor={"#0e1d34"}
						padding={"20px"}
						onClick={onOpen}
					>
						<Icon ml>
							<GiHamburgerMenu color="white" size={"25"} />
						</Icon>
					</Button>
				</Flex>
				<Drawer
					placement="right"
					onClose={onClose}
					isOpen={isOpen}
					display={{ base: "flex", md: "flex", lg: "none" }}
				>
					<DrawerOverlay>
						<DrawerContent
							display={{ base: "flex", md: "flex", lg: "none" }}
							bg={`rgba(14, 29, 52, 0.9)`}
						>
							<DrawerCloseButton />
							<DrawerBody>
								<Flex
									flexDir="column"
									w="100%"
									alignContent={"center"}
									justifyContent={"center"}
									alignItems={"left"}
								>
									<Flex marginY={5}>
										<NavLink
											to="/home"
											onClick={() => {
												patchRoute("Beranda")
											}}
										>
											<Text
												fontFamily={"sans-serif"}
												fontSize={"16px"}
												className="navbar active"
												fontWeight={"semibold"}
												color={{
													textDecor: "#FFFF",
													color: "#FBFBFB",
												}}
												w={"large" && "100%"}
											>
												Beranda
											</Text>
										</NavLink>
									</Flex>
									<Flex marginY={5}>
										<NavLink to="/kebutuhan_ruas">
											<Text
												fontFamily={"sans-serif"}
												fontSize={"16px"}
												className="navbar"
												fontWeight={"semibold"}
											>
												Kebutuhan Ruas
											</Text>
										</NavLink>
									</Flex>
									<Flex marginY={5}>
										<NavLink to="/ruas_terpasang">
											<Text
												fontFamily={"sans-serif"}
												fontSize={"16px"}
												className="navbar"
												fontWeight={"semibold"}
											>
												Ruas Terpasang
											</Text>
										</NavLink>
									</Flex>
									<Flex marginY={5}>
										<NavLink to="/prioritas">
											<Text
												fontFamily={"sans-serif"}
												fontSize={"16px"}
												className="navbar"
												fontWeight={"semibold"}
											>
												Prioritas
											</Text>
										</NavLink>
									</Flex>
									<Flex marginY={5}>
										<NavLink to="/saran_aduan">
											<Text
												fontFamily={"sans-serif"}
												fontSize={"16px"}
												className="navbar"
												fontWeight={"semibold"}
											>
												Saran & Aduan
											</Text>
										</NavLink>
									</Flex>
									<Flex
										marginY={5}
										paddingY={2}
										bg={"#0D42FF"}
										borderRadius={5}
									>
										<NavLink to="/login">
											<Text
												ml={2}
												fontFamily={"sans-serif"}
												color={"white"}
												paddingLeft={5}
												textAlign={"left"}
												alignItems={"left"}
												className="navbar"
												fontWeight={"semibold"}
											>
												Login
											</Text>
										</NavLink>
									</Flex>
								</Flex>
							</DrawerBody>
						</DrawerContent>
					</DrawerOverlay>
				</Drawer>
			</Flex>
			<Flex
				align={"center"}
				py={"40px"}
				bg={showBoxShadow ? "#DBFEFF" : "none"}
				display={{ base: "none", sm: "none", md: "none", lg: "flex" }}
				height={"10vh"}
				position={"fixed"}
				zIndex={"1"}
				p={10}
				blur={4}
				width={"100%"}
				justify={"flex-start"}
				alignItems={"center"}
				flexDir={"row"}
				alignContent={"center"}
				pl={"2%"}
				pr={"2%"}
			>
				<Flex ml={10} float={"left"} mr={"24%"}>
					<Image
						pt={"10px"}
						maxH={"120px"}
						src={process.env.PUBLIC_URL + "/logodishub.png"}
					/>
				</Flex>
				<Flex
					mr={10}
					id={"navbar"}
					alignContent={"center"}
					alignItems={"center"}
					justifyContent={"space-around"}
					width={"100%"}
				>
					<NavLink to="/home">
						<Text
							fontFamily={"sans-serif"}
							className="navbar active"
							fontWeight={"regular"}
						>
							Beranda
						</Text>
					</NavLink>
					<NavLink to="/kebutuhan_ruas">
						<Text
							fontFamily={"sans-serif"}
							className="navbar"
							fontWeight={"regular"}
						>
							Kebutuhan Ruas
						</Text>
					</NavLink>
					<NavLink to="/ruas_terpasang">
						<Text
							fontFamily={"sans-serif"}
							className="navbar"
							fontWeight={"regular"}
						>
							Ruas Terpasang
						</Text>
					</NavLink>
					<NavLink to="/prioritas">
						<Text
							fontFamily={"sans-serif"}
							className="navbar"
							fontWeight={"regular"}
						>
							Prioritas
						</Text>
					</NavLink>
					<NavLink to="/saran_aduan">
						<Text
							fontFamily={"sans-serif"}
							className="navbar"
							fontWeight={"regular"}
						>
							Saran & Aduan
						</Text>
					</NavLink>
					<NavLink to="/login">
						<Flex
							px={"20px"}
							className="login"
							py={"7px"}
							borderRadius={"base"}
							bg={"#0E1D34"}
						>
							<Text
								color={"white"}
								fontFamily={"sans-serif"}
								className="navbar"
								fontWeight={"regular"}
							>
								Login
							</Text>
						</Flex>
					</NavLink>
				</Flex>
			</Flex>
		</>
	)
  // return (
  //   <>
  //   <Flex align={'center'} py={10}  height={'12vh'} position={'fixed'} zIndex={'997'}  bg={showBoxShadow ? '#DBFEFF' : 'none'} blur={4} width={'100vw'} maxWidth={'100vw'} justify={'space-between'} alignItems={'center'} flexDir={'row'} alignContent={'center'} pl={'2%'} pr={'2%'}  display={{ base: "flex", md: "flex", lg: 'none' }}  >
  //   <Image maxH={'65px'} height={'60px'} mt={'2px'} src={process.env.PUBLIC_URL + '/logodishub.png'} maxW={'100%'} />
  //   <Flex float={'right'} display={{ base: "flex" }}>
  //   <Button alignItems={'center'} alignContent={'center'} alignSelf={'center'} textAlign={'center'} bgColor={'#0e1d34'} padding={'20px'} onClick={onOpen}>
  //     <Icon ml>
  //       <GiHamburgerMenu color='white' size={'25'}/>
  //     </Icon>
  //   </Button>
  //   </Flex>
  //   <Drawer placement="right" onClose={onClose} isOpen={isOpen} display={{ base: 'flex', md: 'flex', lg: 'none' }}>
  //     <DrawerOverlay>
  //       <DrawerContent display={{ base: 'flex', md: 'flex', lg: 'none' }} bg={`rgba(14, 29, 52, 0.9)`}>
  //         <DrawerCloseButton />
  //         <DrawerBody >
  //           <Flex flexDir="column" w="100%" alignContent={"center"} justifyContent={"center"} alignItems={"left"}>
  //             <Flex marginY = {5}>
  //               <NavLink to="/home" 
  //               onClick={() => {
	// 						  patchRoute("Beranda");
	// 					  }}><Text fontFamily={'sans-serif'} fontSize={'16px'} className='navbar active' fontWeight={'semibold'} color= {{ textDecor: "#FFFF", color: "#FBFBFB"}} w={"large" && "100%"}>Beranda</Text></NavLink>
  //             </Flex>
  //             <Flex marginY = {5}>
  //               <NavLink to="/kebutuhan_ruas"><Text fontFamily={'sans-serif'} fontSize={'16px'} className='navbar'  fontWeight={'semibold'}>Kebutuhan Ruas</Text></NavLink>
  //             </Flex>
  //             <Flex marginY = {5}>
  //               <NavLink to="/ruas_terpasang"><Text fontFamily={'sans-serif'} fontSize={'16px'} className='navbar' fontWeight={'semibold'}>Ruas Terpasang</Text></NavLink>
  //             </Flex>
  //             <Flex marginY = {5}>
  //               <NavLink to="/prioritas"><Text fontFamily={'sans-serif'} fontSize={'16px'} className='navbar' fontWeight={'semibold'}>Prioritas</Text></NavLink>
  //             </Flex>
  //             <Flex marginY = {5}>
  //               <NavLink to="/saran_aduan"><Text fontFamily={'sans-serif'} fontSize={'16px'} className='navbar' fontWeight={'semibold'}>Saran & Aduan</Text></NavLink>
  //             </Flex>
  //             <Flex marginY={5} paddingY={2}  bg={'#0D42FF'} borderRadius={5}>
  //               <NavLink to="/login"><Text ml={2} fontFamily={'sans-serif'} color={'white'} paddingLeft={5} textAlign={'left'} alignItems={"left"} className='navbar' fontWeight={'semibold'}>Login</Text></NavLink>
  //             </Flex>
  //           </Flex>
  //         </DrawerBody>
  //       </DrawerContent>
  //     </DrawerOverlay>
  //   </Drawer>
  //   </Flex>
  //   <Flex align={'center'} py={'40px'} bg={showBoxShadow ? '#DBFEFF' : 'none'} display={{ base: 'none', sm:'none', md: 'none', lg: 'flex' }} height={'10vh'} position={'fixed'} zIndex={'1'} p={10}  blur={4} width={'100%'} justify={'flex-start'} alignItems={'center'} flexDir={'row'} alignContent={'center'} pl={'2%'} pr={'2%'} > 
  //   <Flex ml={10}  float={'left'} mr={'24%'}>
  //     <Image pt={'10px'} maxH={'120px'}  src={process.env.PUBLIC_URL + '/logodishub.png'} />
  //     </Flex>
  //     <Flex mr={10} id={'navbar'} alignContent={'center'} alignItems={'center'} justifyContent={'space-around'} width={'100%'}>
  //           <NavLink to="/home"><Text fontFamily={'sans-serif'} className='navbar active' fontWeight={'regular'}>Beranda</Text></NavLink>
  //           <NavLink to="/kebutuhan_ruas"><Text fontFamily={'sans-serif'}  className='navbar'  fontWeight={'regular'}>Kebutuhan Ruas</Text></NavLink>
  //           <NavLink to="/ruas_terpasang"><Text fontFamily={'sans-serif'}className='navbar' fontWeight={'regular'}>Ruas Terpasang</Text></NavLink>
  //           <NavLink to="/prioritas"><Text fontFamily={'sans-serif'}className='navbar' fontWeight={'regular'}>Prioritas</Text></NavLink>
  //           <NavLink to="/saran_aduan"><Text fontFamily={'sans-serif'}  className='navbar' fontWeight={'regular'}>Saran & Aduan</Text></NavLink>
  //           <NavLink to="/login">
  //             <Flex px={'20px'} className='login' py={'7px'} borderRadius={'base'} bg={'#0E1D34'}>
  //               <Text color={'white'} fontFamily={'sans-serif'}  className='navbar' fontWeight={'regular'}>Login</Text>
  //             </Flex>
  //           </NavLink>
  //     </Flex>
  //   </Flex>
  //   </>
  // );
}
export default Navbar
