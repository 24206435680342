import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import {
	Flex,
	VStack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Input,
	FormControl,
	FormLabel,
	Button,
} from "@chakra-ui/react"
import { Formik } from "formik"

import { FormInput } from "../../../../Components/Admin/Table/Form/input"
import {
	ButtonDelete,
	ButtonEdit,
} from "../../../../Components/Admin/Table/Button/button"
import {
	FormInputSelected,
	FormInputSelectedChange,
} from "../../../../Components/Admin/Table/Form/inputselected"
import axios from "axios"
import { useToast } from "@chakra-ui/react"

const FormPrioritasPerjal = ({
	user,
	name,
	exist,
	isOpen,
	onClose,
	initialRef,
	finalRef,
	row,
	linkEdit,
	linkCreate,
	category,
	fetchData,
}) => {
	const [state, setState] = useState([])
	const toast = useToast()
	// console.log(row)

	const setInitial = () => {
		// console.log(
		// 	category.data2.find(
		// 		(item) =>
		// 			item.id_kategori_perjal ==
		// 			row.kategori_perjal.id_kategori_perjal
		// 	).nama_perjals
		// )
		// console.log(category.data2)

		setState(category.data3)
	}

	useEffect(() => {
		setInitial()
	}, [category.data3])
	// console.log(category)

	return (
		<Modal
			initialFocusRef={initialRef}
			finalFocusRef={finalRef}
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				{exist ? (
					<ModalHeader>Edit {name}</ModalHeader>
				) : (
					<ModalHeader>Tambah {name}</ModalHeader>
				)}
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Formik
						initialValues={
							exist
								? {
										id_ruas_jalan:
											row.ruas_jalan.id_ruas_jalan,
										id_kategori_perjal:
											row.kategori_perjal
												.id_kategori_perjal,
										id_nama_perjal:
											row.nama_perjal.id_nama_perjal,

										posisi: row.posisi,
										status: row.status,
										keterangan: row.keterangan,
										latitude: row.latitude,
										longitude: row.longitude,
										file_foto_perjal: "",
										prioritas: row.prioritas,

										// foto_perjal_lama: row.foto_perjal,
										category1: category.data1,
										category2: category.data2,
								  }
								: {
										id_ruas_jalan: "",
										id_kategori_perjal: "",
										id_nama_perjal: "",

										posisi: "",
										status: "",
										keterangan: "",
										latitude: "",
										longitude: "",
										file_foto_perjal: "",
										prioritas: "",

										category1: category.data1,
										category2: category.data2,
								  }
						}
						validationSchema={Yup.object({
							id_ruas_jalan: Yup.string().required("Required"),
							id_kategori_perjal:
								Yup.string().required("Required"),
							id_nama_perjal: Yup.string().required("Required"),
							posisi: Yup.string().required("Required"),
							status: Yup.string().required("Required"),
							keterangan: Yup.string().required("Required"),
							latitude: Yup.string().required("Required"),
							longitude: Yup.string().required("Required"),

							prioritas: Yup.string().required("Required"),
						})}
						onSubmit={(values) => {
							if (exist) {
								if (values.status == "Terpasang") {
									values.prioritas = false
								}

								const formData = new FormData()
								formData.append(
									"id_ruas_jalan",
									values.id_ruas_jalan
								)
								formData.append(
									"id_kategori_perjal",
									values.id_kategori_perjal
								)
								formData.append(
									"id_nama_perjal",
									values.id_nama_perjal
								)
								formData.append("posisi", values.posisi)
								formData.append("status", values.status)
								formData.append("keterangan", values.keterangan)
								formData.append("latitude", values.latitude)
								formData.append("longitude", values.longitude)
								formData.append(
									"file_foto_perjal",
									values.file_foto_perjal
								)
								formData.append("prioritas", values.prioritas)

								// console.log(linkEdit)

								try {
									const res = async () => {
										const response = await axios.patch(
											linkEdit,
											formData,
											{
												headers: {
													"Content-Type":
														"multipart/form-data",
												},
											}
										)

										return response
									}
									res().then((response) => {
										if (response.status === 200) {
											toast({
												title: "Berhasil",
												description:
													"Data berhasil diubah",
												status: "success",
												duration: 3000,
												isClosable: true,
											})
											fetchData()
											onClose()
										}
									})
								} catch (error) {
									console.log(error)
								}
							} else {
								try {
									if (values.status == "Terpasang") {
										values.prioritas = false
									}

									if (user == "admin") {
										values.prioritas = false
									}

									const formData = new FormData()
									formData.append(
										"id_ruas_jalan",
										values.id_ruas_jalan
									)
									formData.append(
										"id_kategori_perjal",
										values.id_kategori_perjal
									)
									formData.append(
										"id_nama_perjal",
										values.id_nama_perjal
									)
									formData.append("posisi", values.posisi)
									formData.append("status", values.status)
									formData.append(
										"keterangan",
										values.keterangan
									)
									formData.append("latitude", values.latitude)
									formData.append(
										"longitude",
										values.longitude
									)
									formData.append(
										"file_foto_perjal",
										values.file_foto_perjal
									)
									formData.append(
										"prioritas",
										values.prioritas
									)

									const res = async () => {
										const response = await axios.post(
											linkCreate,
											formData,
											{
												headers: {
													"Content-Type":
														"multipart/form-data",
												},
											}
										)

										return response
									}
									res().then((response) => {
										if (response.status === 201) {
											toast({
												title: "Berhasil",
												description:
													"Data berhasil ditambahkan",
												status: "success",
												duration: 3000,
												isClosable: true,
											})
											fetchData()
											onClose()
										}
									})
								} catch (error) {
									console.log(error)
								}

								// alert(JSON.stringify(values, null, 2))
							}
						}}
					>
						{({
							handleSubmit,
							errors,
							touched,
							values,
							setFieldValue,
						}) => (
							<form onSubmit={handleSubmit}>
								<VStack spacing={2} align="flex-start">
									<FormInputSelected
										id="id_ruas_jalan"
										name="Nama Ruas Jalan"
										errors={errors.id_ruas_jalan}
										touched={touched.id_ruas_jalan}
										options={
											values.category1
												? values.category1.map(
														(item) => {
															return {
																value: item.id_ruas_jalan,
																label: item.nama_ruas_jalan,
															}
														}
												  )
												: []
										}
										placeholder={"Pilih Ruas Jalan"}
									/>

									<FormInputSelectedChange
										id="id_kategori_perjal"
										name="Kategori Perjal"
										errors={errors.id_kategori_perjal}
										touched={touched.id_kategori_perjal}
										options={
											values.category2
												? values.category2.map(
														(item) => {
															return {
																value: item.id_kategori_perjal,
																label: item.nama_kategori_perjal,
															}
														}
												  )
												: []
										}
										placeholder={"Pilih Kategori Perjal"}
										onChange={(event) => {
											setFieldValue(
												"id_kategori_perjal",
												event.target.value
											)

											// console.log(
											// 	values.category2.find(
											// 		(item) =>
											// 			item.id_kategori_perjal ==
											// 			event.target.value
											// 	).nama_perjals
											// )
											if (
												values.category2.find(
													(item) =>
														item.id_kategori_perjal ==
														event.target.value
												).nama_perjals.length > 0
											) {
												setFieldValue(
													"id_nama_perjal",
													values.category2.find(
														(item) =>
															item.id_kategori_perjal ==
															event.target.value
													).nama_perjals[0]
														.id_nama_perjal
												)
											} else {
												setFieldValue(
													"id_nama_perjal",
													""
												)
											}
											setState(
												values.category2.find(
													(item) =>
														item.id_kategori_perjal ==
														event.target.value
												).nama_perjals
											)
										}}
									/>

									<FormInputSelected
										id="id_nama_perjal"
										name="Nama Perjal"
										errors={errors.id_nama_perjal}
										touched={touched.id_nama_perjal}
										options={
											state
												? state.map((item) => {
														return {
															value: item.id_nama_perjal,
															label: item.nama_perjal,
														}
												  })
												: []
										}
										placeholder={"Pilih Nama Perjal"}
									/>

									<Flex mt={5}>
										<FormInput
											id="latitude"
											name="Latitude"
											errors={errors.latitude}
											touched={touched.latitude}
										/>
										<FormInput
											id="longitude"
											name="Longitude"
											errors={errors.longitude}
											touched={touched.longitude}
										/>
									</Flex>

									<Button
										size={"sm"}
										colorScheme="orange"
										width={"100%"}
										mb={5}
										variant={"outline"}
										onClick={() => {
											//get current position
											navigator.geolocation.getCurrentPosition(
												(position) => {
													setFieldValue(
														"latitude",
														position.coords.latitude
													)
													setFieldValue(
														"longitude",
														position.coords
															.longitude
													)
												}
											)
										}}
									>
										Generate Latitude & Longitude
									</Button>

									<FormInputSelected
										id="posisi"
										name="Posisi"
										errors={errors.posisi}
										touched={touched.posisi}
										options={[
											{ value: "Kiri", label: "Kiri" },
											{ value: "Kanan", label: "Kanan" },
											{
												value: "Kiri dan Kanan",
												label: "Kiri dan Kanan",
											},
											{ value: "Tepi", label: "Tepi" },
											{
												value: "Tengah",
												label: "Tengah",
											},
										]}
										placeholder={"Pilih Posisi"}
									/>

									<FormInputSelected
										id="status"
										name="Status"
										errors={errors.status}
										touched={touched.status}
										options={[
											{
												value: "Kebutuhan",
												label: "Kebutuhan",
											},
											{
												value: "Terpasang",
												label: "Terpasang",
											},
										]}
										placeholder={"Pilih Status"}
									/>

									{/* {exist ? (
										<Image
											mt={5}
											alignItems={"center"}
											justifyContent={"center"}
											src={values.foto_perjal_lama}
											alt={values.foto_perjal_lama}
											height="240px"
										/>
									) : null} */}

									{user == "super admin" ? (
										<FormInputSelected
											id="prioritas"
											name="Prioritas"
											errors={errors.prioritas}
											touched={touched.prioritas}
											options={[
												{
													value: true,
													label: "Prioritas",
												},
												{
													value: false,
													label: "Bukan Prioritas",
												},
											]}
											placeholder={"Pilih Prioritas"}
										/>
									) : (
										<></>
									)}

									<FormInput
										id="keterangan"
										name="Keterangan"
										errors={errors.keterangan}
										touched={touched.keterangan}
									/>

									<FormControl
										isInvalid={
											!!errors.file_foto_perjal &&
											touched.file_foto_perjal
										}
									>
										<FormLabel htmlFor="file_foto_perjal">
											{exist
												? "Upload Foto Baru"
												: "Uplad Foto"}
										</FormLabel>
										<Input
											id="file_foto_perjal"
											name="file_foto_perjal"
											type="file"
											onChange={(event) => {
												setFieldValue(
													"file_foto_perjal",
													event.currentTarget.files[0]
												)
											}}
											p={1}
											border={0}
										/>
									</FormControl>

									{exist ? <ButtonEdit /> : <ButtonDelete />}
								</VStack>
							</form>
						)}
					</Formik>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default FormPrioritasPerjal
