import "./App.css"
import React from "react"
import Home from "./Page/Publik/home"
import {
	BrowserRouter,
	Routes,
	Route,
	Redirect,
	Navigate,
} from "react-router-dom"

import AdminBoard from "./Components/Admin/Board/board"
import Dashboard from "./Page/Admin/Dashboard/dashboard"

import PageKebutuhanPerjal from "./Page/Admin/Perjal/Kebutuhan/pagekebutuhanperjal"
import PageTerpasangPerjal from "./Page/Admin/Perjal/Terpasang/pageterpasangperjal"
import PagePrioritasPerjal from "./Page/Admin/Perjal/Prioritas/pageprioritasperjal"
import PageKabupaten from "./Page/Admin/Data/Kabupaten/pagekabupaten"
import PageKategoriPerjal from "./Page/Admin/Data/KategoriPerjal/pagekategoriperjal"
import PageNamaPerjal from "./Page/Admin/Data/NamaPerjal/pagenamaperjal"
import PageRuasJalan from "./Page/Admin/Data/RuasJalan/pageruasjalan"
import PageDRK from "./Page/Admin/Data/DRK/pagedrk"
import PageSaranAduan from "./Page/Admin/Saran/pagesaranaduan"
import PageAkses from "./Page/Admin/Akses/pageakses"

import Board from "./Components/Publik/Board/board"
import KebutuhanRuas from "./Page/Publik/kebutuhan_ruas"
import RuasTerpasang from "./Page/Publik/ruas_terpasang"
import SarandanAduan from "./Page/Publik/saran_dan_aduan"
import Prioritas from "./Page/Publik/prioritas"
import Login from "./Page/Publik/login"
import KabupatenPublik from "./Page/Publik/kabupaten_publik"
import KabupatenPublikRuas from "./Page/Publik/kabupaten_ruas_terpasang"
import KabupatenPublikPrioritas from "./Page/Publik/kabupaten_prioritas"
import RuasKebutuhan from "./Page/Publik/ruas_kebutuhan_jalan"
import RuasJalan from "./Page/Publik/ruas_jalan_terpasang"
import RuasPrioritas from "./Page/Publik/ruas_prioritas_jalan"

function App() {
	return (
		<Routes>
			<Route redirect path="/" element={<Board />}>
				<Route path="/home" element={<Home />} />
				<Route path="/" element={<Navigate to="/home" replace />} />
				<Route path="/kebutuhan_ruas">
					<Route path="/kebutuhan_ruas" element={<KebutuhanRuas />} />
					<Route
						path="/kebutuhan_ruas/kabupaten/:id"
						element={<KabupatenPublik />}
					/>
					<Route
						path="/kebutuhan_ruas/kabupaten/:id/ruas/:id"
						element={<RuasKebutuhan />}
					/>
				</Route>
				<Route path="/ruas_terpasang">
					<Route path="/ruas_terpasang" element={<RuasTerpasang />} />
					<Route
						path="/ruas_terpasang/kabupaten/:id"
						element={<KabupatenPublikRuas />}
					/>
					<Route
						path="/ruas_terpasang/kabupaten/:id/ruas/:id"
						element={<RuasJalan />}
					/>
				</Route>
				<Route path="/prioritas">
					<Route path="/prioritas" element={<Prioritas />} />
					<Route
						path="/prioritas/kabupaten/:id"
						element={<KabupatenPublikPrioritas />}
					/>
					<Route
						path="/prioritas/kabupaten/:id/ruas/:id"
						element={<RuasPrioritas />}
					/>
				</Route>
				<Route path="/saran_aduan" element={<SarandanAduan />} />
			</Route>
			<Route path="/login" element={<Login />} />
			<Route path="/admin" element={<AdminBoard />}>
				<Route path="dashboard" element={<Dashboard />} />
				<Route
					path="/admin"
					element={<Navigate to="/admin/dashboard" replace />}
				/>

				<Route path="perjal">
					<Route path="kebutuhan" element={<PageKebutuhanPerjal />} />
					<Route path="terpasang" element={<PageTerpasangPerjal />} />
					<Route path="prioritas" element={<PagePrioritasPerjal />} />
				</Route>
				<Route path="data">
					<Route path="kabupaten" element={<PageKabupaten />} />
					<Route
						path="kategori-perjal"
						element={<PageKategoriPerjal />}
					/>
					<Route path="nama-perjal" element={<PageNamaPerjal />} />
					<Route path="ruas-jalan" element={<PageRuasJalan />} />
					<Route path="drk" element={<PageDRK />} />
					<Route path="saran-aduan" element={<PageSaranAduan />} />
					<Route path="akses-pengguna" element={<PageAkses />} />
				</Route>
			</Route>
		</Routes>
	)
}

export default App
