import React from "react"
import * as Yup from "yup"
import {
	VStack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Input,
	FormControl,
	FormLabel,
} from "@chakra-ui/react"
import { Formik } from "formik"
import { FormInput } from "../../../Components/Admin/Table/Form/input"
import {
	ButtonDelete,
	ButtonEdit,
} from "../../../Components/Admin/Table/Button/button"
import axios from "axios"
import { useToast } from "@chakra-ui/react"

const FormSaranAduan = ({
	name,
	exist,
	isOpen,
	onClose,
	initialRef,
	finalRef,
	row,
	linkEdit,
	linkCreate,
	category,
	fetchData,
}) => {
	const toast = useToast()

	return (
		<Modal
			initialFocusRef={initialRef}
			finalFocusRef={finalRef}
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				{exist ? (
					<ModalHeader>Edit {name}</ModalHeader>
				) : (
					<ModalHeader>Tambah {name}</ModalHeader>
				)}
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Formik
						initialValues={
							exist
								? {
										file_foto_respon: "",
										keterangan_respon:
											row.keterangan_respon,
										category: category,
								  }
								: {
										file_foto_respon: "",
										keterangan_respon: "",
										category: category,
								  }
						}
						validationSchema={Yup.object({
							// id_ruas_jalan: Yup.string().required("Required"),
						})}
						onSubmit={(values) => {
							if (exist) {
								const formData = new FormData()
								formData.append(
									"keterangan_respon",

									values.keterangan_respon
								)
								formData.append(
									"file_foto_respon",
									values.file_foto_respon
								)
								formData.append("status", true)

								try {
									const res = async () => {
										const response = await axios.patch(
											linkEdit,
											formData,
											{
												headers: {
													"Content-Type":
														"multipart/form-data",
												},
											}
										)

										return response
									}
									res().then((response) => {
										if (response.status === 200) {
											toast({
												title: "Berhasil",
												description:
													"Data berhasil diubah",
												status: "success",
												duration: 3000,
												isClosable: true,
											})
											fetchData()
											onClose()
										}
									})
								} catch (error) {
									console.log(error)
								}
							}
						}}
					>
						{({
							handleSubmit,
							errors,
							touched,
							values,
							setFieldValue,
						}) => (
							<form onSubmit={handleSubmit}>
								<VStack spacing={2} align="flex-start">
									<FormControl
										isInvalid={
											!!errors.file_foto_respon &&
											touched.file_foto_respon
										}
									>
										<FormLabel htmlFor="file_foto_respon">
											{exist
												? "Upload Foto Baru"
												: "Uplad Foto"}
										</FormLabel>
										<Input
											id="file_foto_respon"
											name="file_foto_respon"
											type="file"
											onChange={(event) => {
												setFieldValue(
													"file_foto_respon",
													event.currentTarget.files[0]
												)
											}}
											p={1}
											border={0}
										/>
									</FormControl>

									<FormInput
										id="keterangan_respon"
										name="Deskripsi Respon"
										errors={errors.keterangan_respon}
										touched={touched.keterangan_respon}
									/>
									{exist ? <ButtonEdit /> : <ButtonDelete />}
								</VStack>
							</form>
						)}
					</Formik>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default FormSaranAduan
