import React, { useState, useEffect } from "react"
import {
	Flex,
	Wrap,
	Box,
	Text,
	Image,
	Divider,
	Button,
	useDisclosure,
	FormControl,
	FormLabel,
	Input,
	useToast,
	Select,
} from "@chakra-ui/react"
import { TabTitle } from "../../Components/Admin/Utils/config"
import DataTable from "react-data-table-component"
import moment from "moment"
import MapComponent from "../../Components/Publik/Map/map"
import { Link, NavLink } from "react-router-dom"
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
} from "@chakra-ui/react"
import * as Yup from "yup"
import { Formik, Form, Field } from "formik"
import {
	FormInputSelectedPublic,
	FormInputSelectedChangePublic,
} from "../../Components/Publik/Input/input"
import axios from "axios"
import {
	APISaranAduan,
	APIKabupaten,
} from "../../Components/Admin/Utils/API/api"
import PopupImage from "../../Components/Admin/Table/Popup/Image/image"
import { FormInput } from "../../Components/Admin/Table/Form/input"
const SarandanAduan = () => {
	const toast = useToast()
	TabTitle("Saran dan Aduan")
	const [selectedOption, setSelectedOption] = useState("Saran")
	const [dataKabupatenRuas, setDataKabupatenRuas] = useState([])
	const { isOpen, onOpen, onClose } = useDisclosure()
	const saran = useDisclosure()
	const balasan = useDisclosure()
	const [state, setState] = useState([])
	const [data, setData] = useState([])
	const [dataFiltered, setDataFiltered] = useState([])
	var idLocale = require("moment/locale/id")
	moment.updateLocale("id", idLocale)
	const [selectedRows, setSelectedRows] = useState({
		url_foto_aduan: "",
		url_foto_respon: "",
		kabupaten: {
			nama_kabupaten: "",
		},
		ruas_jalan: {
			nama_ruas_jalan: "",
		},
		keterangan: "",
		keterangan_respon: "",
	})
	const HandlerFilter = (data, e) => {
		const keyword = e.target.value
		if (keyword !== "") {
			const results = data.filter((data) => {
				return (
					data.kabupaten.nama_kabupaten
						.toLowerCase()
						.includes(keyword.toLowerCase()) ||
						data.ruas_jalan.nama_ruas_jalan
						.toLowerCase()
						.includes(keyword.toLowerCase()) ||
					data.keterangan
						.toLowerCase()
						.includes(keyword.toLowerCase())
				)
			})
			setDataFiltered(results)
		} else {
			setDataFiltered(data)
		}
	}

	const fetchData = async () => {
		try {
			axios.get(APISaranAduan.show).then((response) => {
				setData(response.data)
				setDataFiltered(response.data)
			})
		} catch (error) {
			console.log(error)
		}

		try {
			axios.get(APIKabupaten.showRuasByKabupaten).then((response) => {
				setDataKabupatenRuas(response.data)
				setState(response.data[0].ruas_jalans)
			})
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		fetchData()
	}, [])
	function maskEmail(email) {
		const maskedEmail = email.replace(
			/(\d{3})@gmail\.com$/,
			"xxx@gmail.com"
		)
		return maskedEmail
	}

	const customStyles = {
		headCells: {
			style: {
				backgroundColor: "#0e1d34",
				color: "#ffffff",
			},
		},
	}
	const Columns = [
		{
			name: "#",
			selector: "Nomor",
			sortable: true,
			width: "10%",
			cell: (row, index) => {
				return <span>{index + 1}</span>
			},
		},
		{
			name: "Gambar",
			selector: "url_foto_aduan",
			sortable: true,
			width: "70%",
			cell: (row) => {
				const maskEmail = (email) => {
					const [localPart, domain] = email.split("@")
					const maskedLocalPart = `${localPart.slice(0, -3)}xxx`
					return `${maskedLocalPart}@${domain}`
				}
				return (
					<Flex width={"100%"} flexDir={"column"} gap={4}>
						<Flex flexDir={"row"}>
							<Flex >
								<Image
									onClick= {
										() => {
											setSelectedRows(row)
											saran.onOpen()
										}
									}
									width={{ base: "400px", md: "120px" }}
									height={"fit-content"}
									src={row.url_foto_aduan}
								/>
							</Flex>
							<Flex
								gap={2}
								ml={4}
								flexDir={"column"}
								justifyContent={"center"}
								>
								<Flex mt={{base:'2%',md:'0%'}} borderRadius={'10px'} px={2} py={1} bg={'#3155D6'}>
									<Text color={'white'} fontWeight={'bold'}>
										{row.kabupaten.nama_kabupaten+' - '+row.ruas_jalan.nama_ruas_jalan}
									</Text>
								</Flex>
								<Text fontWeight={"semibold"}>
									Email : {maskEmail(row.email)}
								</Text>
								<Text fontWeight={"semibold"}>
									Keterangan : {row.keterangan}
								</Text>
								<Text fontWeight={"semibold"}>
									Tanggal :{" "}
									{moment(row.createdAt).format("LLLL")}
								</Text>
							</Flex>
						</Flex>
						{row.status === true ? (
							<>
								<Flex
									display={{ base: "flex" }}
									justify={"center"}
									flexDir={"row"}
									width={"100%"}
								>
									<Divider
										my={"3%"}
										width={{ base: "25%", md: "45%" }}
										border={"0.6px solid red"}
										opacity={"0.25"}
									/>
									<Flex
										width={{ base: "50%", md: "10%" }}
										alignContent={"center"}
										alignItems={"center"}
										justify={"center"}
									>
										<Text fontWeight={"semibold"}>
											Balasan
										</Text>
									</Flex>
									<Divider
										my={"3%"}
										width={{ base: "25%", md: "45%" }}
										border={"0.6px solid red"}
										opacity={"0.25"}
									/>
								</Flex>
								<Flex
									gap={4}
									flexDir={"row"}
									alignContent={"flex-end"}
									justify={"flex-end"}
								>
									<Text fontWeight={"semibold"}>
										Keterangan : {row.keterangan_respon}
									</Text>
									<Image
									onClick= {
										() => {
											setSelectedRows(row)
											balasan.onOpen()
										}
									}
										width={"120px"}
										height={"auto"}
										src={row.url_foto_respon}
									/>
								</Flex>
							</>
						) : null}
					</Flex>
				)
			},
		},
		{
			name: "Status",
			selector: "status",
			sortable: true,
			width: "20%",
			cell: (row, index) => {
				return (
					<Flex width={'100%'} justify={'center'}>
						{row.status == 0 ? (
							<Flex
								bg={"red"}
								p={2}
								borderRadius={"10px"}
							>
									<Text
										fontWeight={"semibold"}
										fontSize={"16px"}
										color={"white"}
									>
										Belum Diproses
									</Text>
							</Flex>
						) : (
								<Flex  bg={"green"} p={2} borderRadius={"10px"}>
									<Text
										fontWeight={"semibold"}
										fontSize={"16px"}
										color={"white"}
									>
										Sudah Diproses
									</Text>
							</Flex>
						)}
					</Flex>
				)
			},
		},
	]
	const ColumnsBase = [
		{
			name: "#",
			selector: "Nomor",
			sortable: true,
			width: "15%",
			cell: (row, index) => {
				return <span>{index + 1}</span>
			},
		},
		{
			name: "Gambar",
			selector: "url_foto_aduan",
			sortable: true,
			width: "80%",
			cell: (row) => {
				const maskEmail = (email) => {
					const [localPart, domain] = email.split("@")
					const maskedLocalPart = `${localPart.slice(0, -3)}xxx`
					return `${maskedLocalPart}@${domain}`
				}
				return (
					<Flex width={"100%"} flexDir={"column"} gap={4}>
						<PopupImage
							isOpen={saran.isOpen}
							onClose={saran.onClose}
							header={"Ruas Jalan " + selectedRows.ruas_jalan.nama_ruas_jalan}
							image={selectedRows.url_foto_aduan}
							alt={selectedRows.ruas_jalan.nama_ruas_jalan}
							caption={selectedRows.keterangan}
						/>
						<Flex flexDir={"row"}>
							<Flex >
								<Image
									onClick= {
										() => {
											setSelectedRows(row)
											saran.onOpen()
										}
									}
									width={{ base: "400px", md: "120px" }}
									height={"fit-content"}
									src={row.url_foto_aduan}
								/>
							</Flex>
							<Flex
								gap={2}
								ml={4}
								flexDir={"column"}
								justifyContent={"center"}
								>
								<Flex mt={{base:'2%',md:'0%'}} borderRadius={'10px'} px={2} py={1} bg={'#3155D6'}>
									<Text color={'white'} fontWeight={'bold'}>
										{row.kabupaten.nama_kabupaten+' - '+row.ruas_jalan.nama_ruas_jalan}
									</Text>
								</Flex>
								<Text fontWeight={"semibold"}>
									Email : {maskEmail(row.email)}
								</Text>
								<Text fontWeight={"semibold"}>
									Keterangan : {row.keterangan}
								</Text>
								<Text fontWeight={"semibold"}>
									Tanggal :{" "}
									{moment(row.createdAt).format("LLLL")}
								</Text>
							</Flex>
						</Flex>
						{row.status === true ? (
							<>
								<Flex
									display={{ base: "flex" }}
									justify={"center"}
									flexDir={"row"}
									width={"100%"}
								>
									<Divider
										my={"3%"}
										width={{ base: "25%", md: "45%" }}
										border={"0.6px solid red"}
										opacity={"0.25"}
									/>
									<Flex
										width={{ base: "50%", md: "10%" }}
										alignContent={"center"}
										alignItems={"center"}
										justify={"center"}
									>
										<Text fontWeight={"semibold"}>
											Balasan
										</Text>
									</Flex>
									<Divider
										my={"3%"}
										width={{ base: "25%", md: "45%" }}
										border={"0.6px solid red"}
										opacity={"0.25"}
									/>
								</Flex>
								<Flex
									gap={4}
									flexDir={"row"}
									alignContent={"flex-end"}
									justify={"flex-end"}
								>
									<Text fontWeight={"semibold"}>
										Keterangan : {row.keterangan_respon}
									</Text>
									<PopupImage
							isOpen={balasan.isOpen}
							onClose={balasan.onClose}
							header={"Ruas Jalan " + row.ruas_jalan.nama_ruas_jalan}
							image={row.url_foto_respon}
							alt={row.ruas_jalan.nama_ruas_jalan}
							caption={row.keterangan_respon}
						/>
									<Image
										onClick= {
											() => {
												setSelectedRows(row)
												balasan.onOpen()
											}
										}
										width={"120px"}
										height={"auto"}
										src={row.url_foto_respon}
									/>
								</Flex>
							</>
						) : null}
					</Flex>
				)
			},
		},
		{
			name: "Status",
			selector: "status",
			sortable: true,
			width: "37%",
			cell: (row, index) => {
				return (
					<Flex width={'100%'} justify={'center'}>
						{row.status == 0 ? (
							<Flex
								bg={"red"}
								p={2}
								borderRadius={"10px"}
							>
									<Text
										fontWeight={"semibold"}
										fontSize={"16px"}
										color={"white"}
									>
										Belum Diproses
									</Text>
							</Flex>
						) : (
								<Flex  bg={"green"} p={2} borderRadius={"10px"}>
									<Text
										fontWeight={"semibold"}
										fontSize={"16px"}
										color={"white"}
									>
										Sudah Diproses
									</Text>
							</Flex>
						)}
					</Flex>
				)
			},
		},
	]
	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Tambah Saran dan Aduan</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							initialValues={{
								jenis: "",
								email: "",
								no_hp: "",
								keterangan: "",

								id_kabupaten: "",
								id_ruas_jalan: "",
							}}
							validationSchema={Yup.object({
								jenis: Yup.string().required(
									"Jenis harus diisi"
								),
								email: Yup.string()
									.email("Email tidak valid")
									.required("Email harus diisi"),
								no_hp: Yup.string()
									.matches(/^[0-9]+$/, "Hanya boleh angka")
									.required("No HP harus diisi"),
								keterangan: Yup.string().required(
									"Keterangan harus diisi"
								),
								id_kabupaten: Yup.string().required(
									"Kabupaten harus diisi"
								),
								id_ruas_jalan: Yup.string().required(
									"Ruas Jalan harus diisi"
								),
							})}
							onSubmit={(values, actions) => {
								try {
									const formData = new FormData()
									formData.append("jenis", values.jenis)
									formData.append("status", 0)
									formData.append("email", values.email)
									formData.append("no_hp", values.no_hp)
									formData.append(
										"id_kabupaten",
										values.id_kabupaten
									)
									formData.append(
										"id_ruas_jalan",
										values.id_ruas_jalan
									)
									formData.append(
										"file_foto_aduan",
										values.file_foto_aduan
									)
									formData.append(
										"keterangan",
										values.keterangan
									)

									const res = async () => {
										const response = await axios.post(
											APISaranAduan.create,
											formData,
											{
												headers: {
													"Content-Type":
														"multipart/form-data",
												},
											}
										)
										return response
									}
									res().then((response) => {
										if (response.status == 201) {
											toast({
												title: "Berhasil",
												description:
													"Data berhasil ditambahkan",
												status: "success",
												duration: 3000,
												isClosable: true,
											})
											onClose()
											fetchData()
										}
									})
								} catch (error) {
									console.log(error)
								}
							}}
						>
							{({
								errors,
								touched,
								values,
								setFieldValue,
								handleSubmit,
								isSubmitting,
							}) => (
								<Form onSubmit={handleSubmit}>
									<Flex
										flexDir={"column"}
										width={"100%"}
										py={"1%"}
										height={"100%"}
										bgColor={"white"}
									>
										<FormInputSelectedPublic
											id="jenis"
											name="Jenis"
											errors={errors.jenis}
											touched={touched.jenis}
											options={[
												{
													value: "Saran",
													label: "Saran",
												},
												{
													value: "Aduan",
													label: "Aduan",
												},
											]}
											placeholder={"Pilih Jenis"}
										/>

										<FormInputSelectedChangePublic
											id="id_kabupaten"
											name="Kabupaten"
											errors={errors.id_kabupaten}
											touched={touched.id_kabupaten}
											options={
												dataKabupatenRuas
													? dataKabupatenRuas.map(
															(item) => {
																return {
																	value: item.id_kabupaten,
																	label: item.nama_kabupaten,
																}
															}
													  )
													: []
											}
											onChange={(e) => {
												setFieldValue(
													"id_kabupaten",
													e.target.value
												)

												if (
													dataKabupatenRuas.find(
														(item) =>
															item.id_kabupaten ==
															e.target.value
													).ruas_jalans.length > 0
												) {
													setFieldValue(
														"id_ruas_jalan",
														dataKabupatenRuas.find(
															(item) =>
																item.id_kabupaten ==
																e.target.value
														).ruas_jalans[0]
															.id_ruas_jalan
													)
												}

												setState(
													dataKabupatenRuas.find(
														(item) =>
															item.id_kabupaten ==
															e.target.value
													).ruas_jalans
												)
											}}
										/>

										<FormInputSelectedPublic
											id="id_ruas_jalan"
											name="Ruas Jalan"
											errors={errors.id_ruas_jalan}
											touched={touched.id_ruas_jalan}
											options={
												state
													? state.map((item) => {
															return {
																value: item.id_ruas_jalan,
																label: item.nama_ruas_jalan,
															}
													  })
													: []
											}
											placeholder={"Pilih Ruas Jalan"}
										/>

										<FormInput
											id="email"
											name="Email"
											errors={errors.email}
											touched={touched.email}
										/>
										<FormInput
											id="no_hp"
											name="No HP"
											errors={errors.no_hp}
											touched={touched.no_hp}
										/>
										<FormInput
											id="keterangan"
											name="Keterangan"
											errors={errors.keterangan}
											touched={touched.keterangan}
										/>
									</Flex>

									<FormControl
										isInvalid={
											!!errors.file_foto_aduan &&
											touched.file_foto_aduan
										}
									>
										<FormLabel htmlFor="file_foto_aduan">
											Upload Foto
										</FormLabel>
										<Input
											id="file_foto_aduan"
											name="file_foto_aduan"
											type="file"
											onChange={(event) => {
												setFieldValue(
													"file_foto_aduan",
													event.currentTarget.files[0]
												)
											}}
											p={1}
											border={0}
										/>
									</FormControl>

									<Button
										colorScheme="green"
										mr={3}
										type="submit"
									>
										Tambah
									</Button>
								</Form>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>

			<Box
				width={"100%"}
				display="flex"
				flexDirection="column"
				alignItems="center"
				minHeight="100vh"
				overflowY="hidden"
			>
				<Box
					bgGradient="linear(to-b, #E9FCFE, #FAE4ED)"
					width={"100%"}
					display="flex"
					flexDirection="column"
					alignItems="center"
					pb={"2%"}
					minHeight="100vh"
				>
					<Flex
						flexDir={{ base: "column", md: "row" }}
						justifyContent={"flex-start"}
						width={"90%"}
					>
						<Wrap
							display={{ base: "flex" }}
							mt={{ base: "40%", md: "20%", lg: "12%" }}
							alignContent={"flex-start"}
							textAlign={"left"}
							alignItems={"flex-start"}
							alignSelf={"flex-start"}
							width={{ base: "80%", md: "90%" }}
						>
							<Text
								color={"#0e1d34"}
								mt={{ base: "35%", sm: "0%", md: "0%" }}
								fontFamily={"inter"}
								fontSize={{ base: "30px", md: "48px" }}
								fontWeight={"bold"}
							>
								Saran dan Aduan
							</Text>
							<Text
								display={{ base: "none", md: "flex" }}
								color={"#0e1d34"}
								fontFamily={"inter"}
								fontSize={"16px"}
							>
								Berikut merupakan ruas kebutuhan terkait
								perlengkapan jalan meliputi APIL, alat
								penerangan jalan, alat pengendali dan pengaman
								pengguna jalan, marka jalan dan rambu lalu
								lintas di Provinsi Lampung.
							</Text>
							<Button
								mt={5}
								to="/saran-aduan"
								size={"md"}
								colorScheme="blue"
								variant={"solid"}
								onClick={onOpen}
							>
								<Text color={"white"}>
									Tambah Saran dan Aduan
								</Text>
							</Button>
						</Wrap>
						<Flex
							ml={{ base: "0%", md: "10%" }}
							display={{ base: "flex" }}
							justify={"center"}
							mt={{ base: "-100%", md: "20%", lg: "0%" }}
							alignContent={"flex-start"}
							textAlign={"left"}
							alignItems={"flex-start"}
							alignSelf={"flex-start"}
							width={{ base: "100%", md: "80%" }}
						>
							<Image
								height={"fit-content"}
								width={{ base: "70%", md: "80%" }}
								src={process.env.PUBLIC_URL + "/saran.png"}
							/>
						</Flex>
					</Flex>
					<Flex
						width={"90%"}
						mt={{ base: "32%", md: "0%", lg: "1%" }}
					>
						<Box
							width={"100%"}
							display="flex"
							bgColor={"#0e1d34"}
							height={"100%"}
							flexDirection="column"
							borderRadius={"10px"}
							boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.25)"}
						>
							<Flex
								justify={"center"}
								alignContent={"center"}
								alignItems={"center"}
								ml={"10px"}
								mt={"10px"}
								borderTopRadius={"10px"}
								bg="white"
								h={"5%"}
								width={{ base: "40%", md: "20%", lg: "14%" }}
							>
								<Text
									color={"#495057"}
									mt={"2%"}
									ml={"2%"}
									fontFamily={"sans-serif"}
									fontSize={{ base: "16px", md: "16px" }}
									fontWeight={"bold"}
								>
									Saran dan Aduan
								</Text>
							</Flex>
							<Flex
								flexDir={{ base: "column", md: "row" }}
								width={"100%"}
								py={"1%"}
								height={"100%"}
								bgColor={"white"}
							>
								<Flex
									flexDir={{ base: "column", md: "column" }}
									width={{ base: "100%", md: "100%" }}
									mx={{ base: "0%", md: "2%" }}
								>
									<Text
										color={"#495057"}
										mx="10px"
										fontSize={"16px"}
										fontWeight={"semibold"}
									>
										Saran dan Aduan
									</Text>
									<Flex
										my={{ base: "4%", md: "1%" }}
										alignItems={"center"}
										alignContent={"center"}
										flexDir={"row"}
										justify={"space-between"}
										width={{ base: "98%", md: "100%" }}
									>
										<Flex
											ml={{ base: "2%", md: "0%" }}
											width={{ base: "40%", md: "20%" }}
										>
											<Select
												borderColor={"#B1B1B1"}
												variant="outline"
												size="sm"
												borderRadius={"10px"}
												value={selectedOption}
												onChange={(e) =>
													setSelectedOption(
														e.target.value
													)
												}
											>
												<option value="Saran">
													Saran
												</option>
												<option value="Aduan">
													Aduan
												</option>
											</Select>
										</Flex>
										<Flex widrh={"30%"}>
											<Input
												onChange={(e) => {
													HandlerFilter(data, e)
												}}
												borderColor={"#B1B1B1"}
												variant="outline"
												size="sm"
												placeholder="Cari Saran dan Aduan..."
												borderRadius={"10px"}
											/>
										</Flex>
									</Flex>
									<Divider
										my={"1%"}
										width={"99%"}
										border={"0.6px solid"}
										opacity={"0.25"}
									/>
									<Box
										maxWidth={"calc(100vw - 2rem)"}
										width={"100%"}
										height={"100%"}
										margin={{
											base: "0rem",
											md: "0rem",
										}}
										display={{base:'none',md:'block'}}
									>
										<DataTable
											columns={Columns}
											data={dataFiltered
												.filter(
													(item) =>
														item.jenis ===
														selectedOption
												)
												.map((item, index) => ({
													...item,
												}))}
											pagination
											customStyles={customStyles}
											paginationPerPage={10}
											paginationComponentOptions={{
												rowsPerPageText:
													"Baris per halaman:",
												rangeSeparatorText: "dari",
												noRowsPerPage: false,
												selectAllRowsItem: false,
												selectAllRowsItemText: "Semua",
											}}
											responsive
											noDataComponent={
												<Text>Tidak ada data</Text>
											}
										/>
									</Box>
									<Box
										maxWidth={"calc(100vw - 2rem)"}
										width={"100%"}
										height={"100%"}
										margin={{
											base: "0rem",
											md: "0rem",
										}}
										display={{base:'block',sm:'block',md:'none'}}
									>
										<DataTable
											columns={ColumnsBase}
											data={dataFiltered
												.filter(
													(item) =>
														item.jenis ===
														selectedOption
												)
												.map((item, index) => ({
													...item,
												}))}
											pagination
											striped
											customStyles={customStyles}
											paginationPerPage={10}
											paginationComponentOptions={{
												rowsPerPageText:
													"Baris per halaman:",
												rangeSeparatorText: "dari",
												noRowsPerPage: false,
												selectAllRowsItem: false,
												selectAllRowsItemText: "Semua",
											}}
											responsive
											noDataComponent={
												<Text>Tidak ada data</Text>
											}
										/>
									</Box>
								</Flex>
							</Flex>
						</Box>
					</Flex>
				</Box>
			</Box>
		</>
	)
}
export default SarandanAduan
