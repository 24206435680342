import React, { useEffect, useState } from "react"
import axios from "axios"
import { useDispatch } from "react-redux"
import { Text } from "@chakra-ui/react"

import Action from "../../../Components/Admin/Table/Action/action"
import { routePageName } from "../../../Components/Admin/Utils/Redux/action"
import { TabTitle } from "../../../Components/Admin/Utils/config"
import ContainerBox from "../../../Components/Admin/Table/Container/container"

import FormSaranAduan from "./formsaranaduan"
import { APISaranAduan } from "../../../Components/Admin/Utils/API/api"

import { ExpandSaranAduan } from "./expandsaranaduan"
import useAuth from "../../../middleware/useAuth"
import getUserLogin from "../../../middleware/getUserLogin"

const HandlerFilter = (data, e) => {
	const keyword = e.target.value
	if (keyword !== "") {
		const results = data.filter((data) => {
			return (
				data.email.toLowerCase().includes(keyword.toLowerCase()) ||
				data.no_hp.toLowerCase().includes(keyword.toLowerCase()) ||
				data.ruas_jalan.nama_ruas_jalan
					.toLowerCase()
					.includes(keyword.toLowerCase()) ||
				data.kabupaten.nama_kabupaten
					.toLowerCase()
					.includes(keyword.toLowerCase())
			)
		})
		return results
	} else {
		return data
	}
}

const API = APISaranAduan
const name = "Saran Dan Aduan"
const Form = FormSaranAduan

const PageSaranAduan = () => {
	useAuth()

	const [user, setUser] = useState()
	const linkCreate = API.create
	const filter = HandlerFilter

	TabTitle(name)
	const [data, setData] = useState()

	const dispatch = useDispatch()
	const patchRoute = (data) => {
		dispatch(routePageName(data))
	}

	const Columns = [
		{
			name: "No",
			selector: "id_saran_dan_aduan",
			sortable: true,
			width: "100px",
			cell: (row, index) => {
				return <span>{index + 1}</span>
			},
		},
		{
			name: "Jenis",
			selector: "jenis",
			sortable: true,
		},
		{
			name: "Email",
			selector: "email",
			sortable: true,
		},
		{
			name: "No HP",
			selector: "no_hp",
			sortable: true,
		},
		{
			name: "Nama Ruas Jalan",
			selector: "ruas_jalan.nama_ruas_jalan",
			sortable: true,
		},
		{
			name: "Nama Kabupaten",
			selector: "kabupaten.nama_kabupaten",
			sortable: true,
		},
		{
			name: "Status",
			selector: "status",
			sortable: true,
			cell: (row) => (
				<Text
					bg={row.status ? "green.200" : "red.200"}
					p={2}
					borderRadius={5}
				>
					{row.status ? "Sudah Ditanggapi" : "Belum Ditanggapi"}
				</Text>
			),
		},
		user === "super admin"
			? {
					name: "Aksi",
					center: true,
					width: "240px",
					cell: (row) => (
						<Action
							id={row.id_saran_dan_aduan}
							nameSelected={row.nama_ruas_jalan}
							APIEdit={API.edit}
							APIDelete={API.delete}
							row={row}
							Edit={Form}
							name={name}
							aduan={true}
							fetchData={fetchData}
						/>
					),
			  }
			: {
					width: "0px",
			  },
	]

	const fetchData = async () => {
		try {
			axios.get(API.show).then((response) => {
				setData(response.data)
			})
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		fetchData()
		patchRoute(name)
		getUserLogin().then((res) => {
			setUser(res.role)
		})
	}, [])

	return (
		<ContainerBox
			Create={Form}
			name={name}
			records={data}
			columns={Columns}
			filter={filter}
			linkCreate={linkCreate}
			expand={true}
			ComponentExpanded={ExpandSaranAduan}
			saran={true}
		/>
	)
}

export default PageSaranAduan
