import React from "react"
import {
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
} from "@chakra-ui/react"
import { Field } from "formik"

export const FormInput = ({ id, name, errors, touched }) => {
	return (
		<FormControl isInvalid={!!errors && touched}>
			<FormLabel htmlFor={id}>{name}</FormLabel>
			<Field as={Input} id={id} name={id} type={id} />
			<FormErrorMessage>{errors}</FormErrorMessage>
		</FormControl>
	)
}
export const FormInputPassword = ({ id, name, errors, touched }) => {
	return (
		<FormControl isInvalid={!!errors && touched}>
			<FormLabel htmlFor={id}>{name}</FormLabel>
			<Field as={Input} id={id} name={id} type="password" />
			<FormErrorMessage>{errors}</FormErrorMessage>
		</FormControl>
	)
}
