import React from "react"
import * as Yup from "yup"
import {
	VStack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Flex,
	Button,
} from "@chakra-ui/react"
import { Formik } from "formik"
import { FormInput } from "../../../../Components/Admin/Table/Form/input"
import {
	ButtonDelete,
	ButtonEdit,
} from "../../../../Components/Admin/Table/Button/button"
import {
	FormInputSelected,
	FormInputSelectedAktif,
} from "../../../../Components/Admin/Table/Form/inputselected"
import axios from "axios"
import { useToast } from "@chakra-ui/react"

const FormRuasJalan = ({
	name,
	exist,
	isOpen,
	onClose,
	initialRef,
	finalRef,
	row,
	linkEdit,
	linkCreate,
	category,
	fetchData,
}) => {
	const toast = useToast()

	return (
		<Modal
			initialFocusRef={initialRef}
			finalFocusRef={finalRef}
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				{exist ? (
					<ModalHeader>Edit {name}</ModalHeader>
				) : (
					<ModalHeader>Tambah {name}</ModalHeader>
				)}
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Formik
						initialValues={
							exist
								? {
										id_ruas_jalan: row.id_ruas_jalan,
										nama_ruas_jalan: row.nama_ruas_jalan,
										panjang: row.panjang,
										status: row.status,
										fungsi: row.fungsi,
										remark: row.remark,
										sumber: row.sumber,
										start_latitude: row.start_latitude,
										start_longitude: row.start_longitude,
										end_latitude: row.end_latitude,
										end_longitude: row.end_longitude,
										id_kabupaten:
											row.kabupaten.id_kabupaten,

										category: category,
								  }
								: {
										id_ruas_jalan: "",
										nama_ruas_jalan: "",
										panjang: "",
										status: "",
										fungsi: "",
										remark: "",
										id_kabupaten: "",
										sumber: "",
										start_latitude: "",
										start_longitude: "",
										end_latitude: "",
										end_longitude: "",

										category: category,
								  }
						}
						validationSchema={Yup.object({
							id_ruas_jalan: Yup.string().required("Required"),
							nama_ruas_jalan: Yup.string().required("Required"),
							panjang: Yup.string().required("Required"),
							status: Yup.string().required("Required"),
							fungsi: Yup.string().required("Required"),
							remark: Yup.string().required("Required"),
							sumber: Yup.string().required("Required"),
							start_latitude: Yup.number().required("Required"),
							start_longitude: Yup.number().required("Required"),
							end_latitude: Yup.number().required("Required"),
							end_longitude: Yup.number().required("Required"),
							id_kabupaten: Yup.string().required("Required"),
						})}
						onSubmit={(values) => {
							if (exist) {
								// values.link = linkEdit
								// alert(JSON.stringify(values, null, 2))

								const submitted = {
									id_ruas_jalan: values.id_ruas_jalan,
									nama_ruas_jalan: values.nama_ruas_jalan,
									panjang: values.panjang,
									status: values.status,
									fungsi: values.fungsi,
									remark: values.remark,
									sumber: values.sumber,
									start_latitude: values.start_latitude,
									start_longitude: values.start_longitude,
									end_latitude: values.end_latitude,
									end_longitude: values.end_longitude,
									id_kabupaten: values.id_kabupaten,
								}

								try {
									const res = async () => {
										const response = await axios.patch(
											linkEdit,
											submitted
										)

										return response
									}
									res().then((response) => {
										if (response.status === 200) {
											toast({
												title: "Berhasil",
												description:
													"Data berhasil diubah",
												status: "success",
												duration: 3000,
												isClosable: true,
											})
											fetchData()
											onClose()
										}
									})
								} catch (error) {
									toast({
										title: "Gagal",
										description: "Data gagal diubah",
										status: "error",
										duration: 3000,
										isClosable: true,
									})
									console.log(error)
								}
							} else {
								const submitted = {
									id_ruas_jalan: values.id_ruas_jalan,
									nama_ruas_jalan: values.nama_ruas_jalan,
									panjang: values.panjang,
									status: values.status,
									fungsi: values.fungsi,
									remark: values.remark,
									sumber: values.sumber,
									start_latitude: values.start_latitude,
									start_longitude: values.start_longitude,
									end_latitude: values.end_latitude,
									end_longitude: values.end_longitude,
									id_kabupaten: values.id_kabupaten,
								}

								try {
									const res = async () => {
										const response = await axios.post(
											linkCreate,
											submitted
										)

										return response
									}
									res().then((response) => {
										if (response.status === 201) {
											toast({
												title: "Berhasil",
												description:
													"Data berhasil ditambahkan",
												status: "success",
												duration: 3000,
												isClosable: true,
											})
											fetchData()
											onClose()
										}
									})
								} catch (error) {
									console.log(error)
								}
							}
						}}
					>
						{({
							handleSubmit,
							errors,
							touched,
							values,
							setFieldValue,
						}) => (
							<form onSubmit={handleSubmit}>
								<VStack spacing={2} align="flex-start">
									<FormInput
										id="id_ruas_jalan"
										name="Nomor Ruas"
										errors={errors.id_ruas_jalan}
										touched={touched.id_ruas_jalan}
									/>
									<FormInput
										id="nama_ruas_jalan"
										name="Nama Ruas Jalan"
										errors={errors.nama_ruas_jalan}
										touched={touched.nama_ruas_jalan}
									/>
									<FormInput
										id="panjang"
										name="Panjang"
										errors={errors.panjang}
										touched={touched.panjang}
									/>

									<FormInputSelected
										id="status"
										name="Status"
										errors={errors.status}
										touched={touched.status}
										options={[
											{
												value: "Jalan Nasional",
												label: "Jalan Nasional",
											},
											{
												value: "Jalan Provinsi",
												label: "Jalan Provinsi",
											},
											{
												value: "Jalan Kabupaten",
												label: "Jalan Kabupaten",
											},
											{
												value: "Jalan Kota",
												label: "Jalan Kota",
											},
											{
												value: "Jalan Desa",
												label: "Jalan Desa",
											},
										]}
									/>

									<FormInputSelected
										id="fungsi"
										name="Fungsi"
										errors={errors.fungsi}
										touched={touched.fungsi}
										options={[
											{
												value: "JSP",
												label: "JSP",
											},
											{
												value: "JKP-2",
												label: "JKP-2",
											},
											{
												value: "JKP-3",
												label: "JKP-3",
											},
										]}
										placeholder={"Pilih Fungsi"}
									/>

									<FormInputSelected
										id="remark"
										name="Remark"
										errors={errors.remark}
										touched={touched.remark}
										options={[
											{
												value: "Jalan Kolektor",
												label: "Jalan Kolektor",
											},
											{
												value: "Jalan Lokal",
												label: "Jalan Lokal",
											},
											{
												value: "Jalan Lain",
												label: "Jalan Lain",
											},
										]}
										placeholder={"Pilih Remark"}
									/>

									<FormInputSelected
										id="id_kabupaten"
										name="Kabupaten"
										errors={errors.id_kabupaten}
										touched={touched.id_kabupaten}
										options={
											values.category
												? values.category.map(
														(item) => {
															return {
																value: item.id_kabupaten,
																label: item.nama_kabupaten,
															}
														}
												  )
												: []
										}
										placeholder={"Pilih Kabupaten"}
									/>

									<FormInput
										id="sumber"
										name="Sumber"
										errors={errors.sumber}
										touched={touched.sumber}
									/>

									<Flex mt={5}>
										<FormInput
											id="start_latitude"
											name="Start Latitude"
											errors={errors.start_latitude}
											touched={touched.start_latitude}
										/>
										<FormInput
											id="start_longitude"
											name="Start Longitude"
											errors={errors.start_longitude}
											touched={touched.start_longitude}
										/>
									</Flex>
									<Button
										size={"sm"}
										colorScheme="orange"
										width={"100%"}
										mb={5}
										variant={"outline"}
										onClick={() => {
											//get current position
											navigator.geolocation.getCurrentPosition(
												(position) => {
													setFieldValue(
														"start_latitude",
														position.coords.latitude
													)
													setFieldValue(
														"start_longitude",
														position.coords
															.longitude
													)
												}
											)
										}}
									>
										Generate Start Latitude & Longitude
									</Button>
									<Flex mt={5}>
										<FormInput
											id="end_latitude"
											name="End Latitude"
											errors={errors.end_latitude}
											touched={touched.end_latitude}
										/>
										<FormInput
											id="end_longitude"
											name="End Longitude"
											errors={errors.end_longitude}
											touched={touched.end_longitude}
										/>
									</Flex>
									<Button
										size={"sm"}
										colorScheme="orange"
										width={"100%"}
										mb={5}
										variant={"outline"}
										onClick={() => {
											//get current position
											navigator.geolocation.getCurrentPosition(
												(position) => {
													setFieldValue(
														"end_latitude",
														position.coords.latitude
													)
													setFieldValue(
														"end_longitude",
														position.coords
															.longitude
													)
												}
											)
										}}
									>
										Generate End Latitude & Longitude
									</Button>

									{exist ? <ButtonEdit /> : <ButtonDelete />}
								</VStack>
							</form>
						)}
					</Formik>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default FormRuasJalan
