import React from "react"
import {
	Button,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	useToast,
} from "@chakra-ui/react"
import axios from "axios"
import { logoutUser } from "../../../../Publik/Util/api"
import { useDispatch } from "react-redux"
import { routePageName } from "../../../Utils/Redux/action"
import { useNavigate } from "react-router-dom"

const PopupLogout = ({ isOpen, onClose, cancelRef }) => {
	const toast = useToast()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	return (
		<AlertDialog
			isCentered
			size={"sm"}
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize="xl" fontWeight="bold">
						Logout
					</AlertDialogHeader>

					<AlertDialogBody>
						Apakah anda yakin ingin Keluar
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose}>
							Cancel
						</Button>
						<Button
							colorScheme="red"
							onClick={() => {
								const logout = async () => {
									try {
										await axios
											.delete(logoutUser, {
												withCredentials: true,
											})
											.then(() => {
												dispatch(
													routePageName("Dashboard")
												)
												navigate("/login")
											})
									} catch (error) {
										console.error("Logout error:", error)
									}
								}
								logout()
								onClose()
							}}
							ml={3}
						>
							Keluar
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	)
}

export default PopupLogout
