import React from "react"
import * as Yup from "yup"
import {
	VStack,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Input,
	FormControl,
	FormLabel,
	useToast,
} from "@chakra-ui/react"
import { Formik } from "formik"
import {
	FormInput,
	FormInputPassword,
} from "../../../Components/Admin/Table/Form/input"
import {
	ButtonDelete,
	ButtonEdit,
} from "../../../Components/Admin/Table/Button/button"
import { FormInputSelected } from "../../../Components/Admin/Table/Form/inputselected"
import axios from "axios"

const FormAkses = ({
	name,
	exist,
	isOpen,
	onClose,
	initialRef,
	finalRef,
	row,
	linkEdit,
	linkCreate,
	fetchData,
}) => {
	const toast = useToast()
	return (
		<Modal
			initialFocusRef={initialRef}
			finalFocusRef={finalRef}
			isOpen={isOpen}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				{exist ? (
					<ModalHeader>Edit {name}</ModalHeader>
				) : (
					<ModalHeader>Tambah {name}</ModalHeader>
				)}
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Formik
						initialValues={
							exist
								? {
										username: row.username,
										password: "",
										confPassword: "",
										role: row.role,
								  }
								: {
										username: "",
										password: "",
										confPassword: "",
										role: "",
								  }
						}
						validationSchema={
							exist
								? Yup.object({
										username:
											Yup.string().required("Required"),
										role: Yup.string().required("Required"),
								  })
								: Yup.object({
										username:
											Yup.string().required("Required"),
										password:
											Yup.string().required("Required"),
										confPassword: Yup.string()
											.oneOf(
												[Yup.ref("password"), null],
												"Passwords must match"
											)
											.required("Required"),
										role: Yup.string().required("Required"),
								  })
						}
						onSubmit={(values) => {
							if (exist) {
								const submitted = {
									username: values.username,
									password: values.password,
									confPassword: values.confPassword,
									role: values.role,
								}

								try {
									const res = async () => {
										const response = await axios.patch(
											linkEdit,
											submitted,
											{
												withCredentials: true,
											}
										)

										return response
									}
									res().then((response) => {
										if (response.status === 201) {
											toast({
												title: "Berhasil",
												description:
													"Data berhasil diubah",
												status: "success",
												duration: 3000,
												isClosable: true,
											})
											fetchData()
											onClose()
										}
									})
								} catch (error) {
									toast({
										title: "Gagal",
										description: "Data gagal diubah",
										status: "error",
										duration: 3000,
										isClosable: true,
									})
									console.log(error)
								}
							} else {
								const submitted = {
									username: values.username,
									password: values.password,
									confPassword: values.confPassword,
									role: values.role,
								}

								// console.log("DATA: ", submitted)
								// console.log("LINK: ", linkCreate)

								try {
									const res = async () => {
										const response = await axios.post(
											linkCreate,
											submitted,
											{
												withCredentials: true,
											}
										)

										return response
									}
									res().then((response) => {
										if (response.status === 201) {
											toast({
												title: "Berhasil",
												description:
													"Data berhasil ditambahkan",
												status: "success",
												duration: 3000,
												isClosable: true,
											})
											fetchData()
											onClose()
										}
									})
								} catch (error) {
									console.log(error)
								}
							}
						}}
					>
						{({
							handleSubmit,
							errors,
							touched,
							values,
							setFieldValue,
						}) => (
							<form onSubmit={handleSubmit}>
								<VStack spacing={2} align="flex-start">
									<FormInput
										id="username"
										name="Username"
										errors={errors.username}
										touched={touched.username}
									/>
									<FormInput
										id="password"
										name="Password"
										errors={errors.password}
										touched={touched.password}
									/>
									<FormInputPassword
										id="confPassword"
										name="confPassword"
										errors={errors.confPassword}
										touched={touched.confPassword}
									/>

									<FormInputSelected
										id="role"
										name="Role"
										errors={errors.role}
										touched={touched.role}
										options={[
											{
												value: "super admin",
												label: "Super Admin",
											},
											{
												value: "admin",
												label: "Admin",
											},
										]}
										placeholder={"Pilih Role"}
									/>

									{exist ? <ButtonEdit /> : <ButtonDelete />}
								</VStack>
							</form>
						)}
					</Formik>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default FormAkses
