import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import axios from "axios"
import { token } from "../Components/Publik/Util/api"
import Cookies from "js-cookie"
import jwt_decode from "jwt-decode"

const useAuth = () => {
	const navigate = useNavigate()

	const checkToken = useCallback(async () => {
		try {
			await axios
				.get(token, { withCredentials: true })
				.then(async (res) => {
					const decoded = await jwt_decode(res.data.accessToken)
					return res
				})
			// console.log(response.data)
		} catch (error) {
			console.error(error)
			navigate("/login")
			if (error.response && error.response.status === 401) {
				navigate("/login")
			} else {
				console.error("Terjadi kesalahan saat memeriksa token:", error)
			}
		}
	}, [navigate])

	useEffect(() => {
		checkToken()
	}, [checkToken])

	return null
}

export default useAuth
