import {
	Table,
	Tbody,
	Tr,
	Td,
	Box,
	Image,
	useDisclosure,
	Flex,
} from "@chakra-ui/react"
import PopupImage from "../../../../Components/Admin/Table/Popup/Image/image"

export const ExpandPrioritasPerjal = (props) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<Box p={5}>
			<PopupImage
				isOpen={isOpen}
				onClose={onClose}
				props={props}
				header={"Perjal " + props.data.nama_perjal.nama_perjal}
				image={props.data.url_foto_perjal}
				alt={props.data.nama_perjal.nama_perjal}
				caption={props.data.keterangan}
			/>
			<Table size={"sm"} variant={"simple"} colorScheme="blue">
				{/* <Thead>
					<Tr>
						<Th>Label</Th>
						<Th>Value</Th>
					</Tr>
				</Thead> */}
				<Tbody>
					<Tr>
						<Td fontWeight={"bold"}>Nama Ruas Jalan</Td>
						<Td>{props.data.ruas_jalan.nama_ruas_jalan}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Nama Kategori Perjal</Td>
						<Td>
							{props.data.kategori_perjal.nama_kategori_perjal}
						</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Nama Perjal</Td>
						<Td>{props.data.nama_perjal.nama_perjal}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Posisi</Td>
						<Td>{props.data.posisi}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Status</Td>
						<Td>
							{props.data.status === 0
								? "Belum Terpasang"
								: "Terpasang"}
						</Td>
					</Tr>

					<Tr>
						<Td fontWeight={"bold"}>Latitude</Td>
						<Td>{props.data.latitude}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Longitude</Td>
						<Td>{props.data.longitude}</Td>
					</Tr>

					<Tr>
						<Td fontWeight={"bold"}>Prioritas</Td>
						{props.data.prioritas == false ? (
							<Td>Tidak Prioritas</Td>
						) : (
							<Td>Prioritas</Td>
						)}
					</Tr>

					<Tr>
						<Td fontWeight={"bold"}>Foto</Td>
						<Td onClick={onOpen}>
							<Flex>
								<Image
									_hover={{ cursor: "pointer" }}
									src={props.data.url_foto_perjal}
									alt={props.data.nama_perjal.nama_perjal}
									width="240px"
								/>
							</Flex>
						</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Keterangan</Td>
						<Td>{props.data.keterangan}</Td>
					</Tr>
				</Tbody>
			</Table>
		</Box>
	)
}
