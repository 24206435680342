import {
	Table,
	Text,
	Tbody,
	Tr,
	Td,
	Box,
	Image,
	useDisclosure,
	Flex,
} from "@chakra-ui/react"
import PopupImage from "../../../Components/Admin/Table/Popup/Image/image"

export const ExpandSaranAduan = (props) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const tanggapan = useDisclosure()

	return (
		<Box p={5}>
			<PopupImage
				isOpen={isOpen}
				onClose={onClose}
				header={"Ruas Jalan " + props.data.ruas_jalan.nama_ruas_jalan}
				image={props.data.url_foto_aduan}
				alt={props.data.ruas_jalan.nama_ruas_jalan}
				caption={props.data.keterangan}
			/>
			<PopupImage
				isOpen={tanggapan.isOpen}
				onClose={tanggapan.onClose}
				header={"Ruas Jalan " + props.data.ruas_jalan.nama_ruas_jalan}
				image={props.data.url_foto_respon}
				alt={props.data.ruas_jalan.nama_ruas_jalan}
				caption={props.data.keterangan_respon}
			/>
			<Table size={"sm"} variant={"simple"} colorScheme="blue">
				{/* <Thead>
					<Tr>
						<Th>Label</Th>
						<Th>Value</Th>
					</Tr>
				</Thead> */}
				<Tbody>
					<Tr>
						<Td fontWeight={"bold"}>Jenis</Td>
						<Td>{props.data.jenis}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Email</Td>
						<Td>{props.data.email}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>No HP</Td>
						<Td>{props.data.no_hp}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Nama Kabupaten</Td>
						<Td>{props.data.kabupaten.nama_kabupaten}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Nama Ruas Jalan</Td>
						<Td>{props.data.ruas_jalan.nama_ruas_jalan}</Td>
					</Tr>
					<Tr>
						<Td fontWeight={"bold"}>Status</Td>
						<Td>
							{props.data.status
								? "Sudah Ditanggapi"
								: "Belum Ditanggapi"}
						</Td>
					</Tr>

					<Tr>
						<Td fontWeight={"bold"}>Foto Saran / Aduan</Td>
						<Td onClick={onOpen}>
							<Flex direction="column">
								<Image
									_hover={{ cursor: "pointer" }}
									src={props.data.url_foto_aduan}
									alt={props.data.nama_ruas_jalan}
									width="360px"
								/>
								<Text>
									<b>Keterangan : </b>
									{props.data.keterangan}
								</Text>
							</Flex>
						</Td>
					</Tr>
					{props.data.status ? (
						<Tr>
							<Td fontWeight={"bold"}>Tanggapan</Td>
							<Td onClick={tanggapan.onOpen}>
								<Flex direction="column">
									<Image
										_hover={{ cursor: "pointer" }}
										src={props.data.url_foto_respon}
										alt={props.data.nama_ruas_jalan}
										width="360px"
									/>
									<Text>
										<b>Keterangan : </b>
										{props.data.keterangan_respon}
									</Text>
								</Flex>
							</Td>
						</Tr>
					) : null}
				</Tbody>
			</Table>
		</Box>
	)
}
