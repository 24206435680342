import jwt_decode from "jwt-decode"
import axios from "axios"
import { token } from "../Components/Publik/Util/api"

const getUserLogin = async () => {
	try {
		const res = await axios
			.get(token, { 
				withCredentials: true,
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Credentials": true,
					"Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
					"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
				},
			 })
			.then(async (res) => {
				const decoded = await jwt_decode(res.data.accessToken)
				return decoded
			})
		return res
	} catch (error) {
		return error
	}
}

export default getUserLogin
