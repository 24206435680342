import React, { useEffect, useState } from "react"
import axios from "axios"
import { useDispatch } from "react-redux"

import Action from "../../../../Components/Admin/Table/Action/action"
import { routePageName } from "../../../../Components/Admin/Utils/Redux/action"
import { TabTitle } from "../../../../Components/Admin/Utils/config"
import ContainerBox from "../../../../Components/Admin/Table/Container/container"

import FormTerpasangPerjal from "./formterpasangperjal"
import {
	APIPerjal,
	APIRuasJalan,
	APIKategoriPerjal,
} from "../../../../Components/Admin/Utils/API/api"

import { ExpandTerpasangPerjal } from "./expandterpasangoperjal"
import useAuth from "../../../../middleware/useAuth"
import getUserLogin from "../../../../middleware/getUserLogin"

const HandlerFilter = (data, e) => {
	const keyword = e.target.value
	if (keyword !== "") {
		const results = data.filter((data) => {
			return (
				data.ruas_jalan.nama_ruas_jalan
					.toLowerCase()
					.includes(keyword.toLowerCase()) ||
				data.nama_perjal.nama_perjal
					.toLowerCase()
					.includes(keyword.toLowerCase()) ||
				data.kategori_perjal.nama_kategori_perjal
					.toLowerCase()
					.includes(keyword.toLowerCase())
			)
		})
		return results
	} else {
		return data
	}
}

const HandlerFilterDropDown = (data, eCat1, eCat2) => {
	const keyword1 = eCat1
	const keyword2 = eCat2

	// console.log("KEYWORD : ", keyword)
	// console.log("TES : ", data[0].ruas_jalan.id_ruas_jalan)
	if (keyword1 !== "") {
		const results = data.filter(
			(data) => data.ruas_jalan.id_ruas_jalan == keyword1
		)
		if (keyword2 !== "") {
			const results2 = results.filter(
				(data) => data.kategori_perjal.id_kategori_perjal == keyword2
			)
			return results2
		}
		// console.log("RESULT : ", results)
		return results
	} else {
		return data
	}
}

const API = APIPerjal
const API2 = APIRuasJalan
const API3 = APIKategoriPerjal
const name = "Perjal Terpasang"
const Form = FormTerpasangPerjal

// const data = dataPerjal
// const dataCategory = {
// 	data1: dataRuasJalan,
// 	data2: dataKategori,
// }

const PageTerpasangPerjal = () => {
	useAuth()

	const [user, setUser] = useState()
	const linkCreate = API.create
	const filter = HandlerFilter

	TabTitle(name)
	const [data, setData] = useState()
	const [dataCategory, setDataCategory] = useState({
		data1: [],
		data2: [],
		data3: [],
	})

	const dispatch = useDispatch()
	const patchRoute = (data) => {
		dispatch(routePageName(data))
	}

	const Columns = [
		{
			name: "No",
			selector: "id_perjal",
			sortable: true,
			width: "100px",
			cell: (row, index) => {
				return <span>{index + 1}</span>
			},
		},
		{
			name: "Nama Ruas Jalan",
			selector: "ruas_jalan.nama_ruas_jalan",
			sortable: true,
		},
		{
			name: "Kategori",
			selector: "kategori_perjal.nama_kategori_perjal",
			sortable: true,
		},
		{
			name: "Nama Perjal",
			selector: "nama_perjal.nama_perjal",
			sortable: true,
		},
		// {
		// 	name: "Posisi",
		// 	selector: "posisi",
		// 	sortable: true,
		// },
		{
			name: "Status",
			selector: "status",
			sortable: true,
		},

		user === "super admin"
			? {
					name: "Aksi",
					center: true,
					width: "240px",
					cell: (row) => (
						<Action
							id={row.id_perjal}
							nameSelected={row.ruas_jalan.nama_ruas_jalan}
							APIEdit={API.edit}
							APIDelete={API.delete}
							row={row}
							Edit={Form}
							name={name}
							category={dataCategory}
							dropdown={true}
							fetchData={fetchData}
						/>
					),
			  }
			: {
					width: "0px",
			  },
	]

	const fetchData = async () => {
		try {
			axios.get(API.perjalParamsOwn).then((response) => {
				setData(response.data)
				// console.log("DATA : ", response.data.length)
				// console.log(
				// 	"DATA : ",
				// 	response.data[0].kategori_perjal.nama_perjals
				// )
				if (response.data.length > 0) {
					setDataCategory((prevState) => ({
						...prevState,
						data3: response.data[0].kategori_perjal.nama_perjals,
					}))
				}
			})
		} catch (error) {
			console.log(error)
		}

		try {
			axios.get(API2.show).then((response) => {
				setDataCategory((prevState) => ({
					...prevState,
					data1: response.data,
				}))
			})
		} catch (error) {
			console.log(error)
		}

		try {
			axios.get(API3.kategoriParams).then((response) => {
				setDataCategory((prevState) => ({
					...prevState,
					data2: response.data,
				}))
			})
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		fetchData()
		patchRoute(name)
		getUserLogin().then((res) => {
			setUser(res.role)
		})
	}, [])

	return (
		<ContainerBox
			user={user}
			Create={Form}
			name={name}
			records={data}
			columns={Columns}
			filter={filter}
			linkCreate={linkCreate}
			expand={true}
			ComponentExpanded={ExpandTerpasangPerjal}
			category={dataCategory}
			dropdown={true}
			dropdownCategory1={dataCategory.data1.map((data) => {
				return {
					value: data.id_ruas_jalan,
					label: data.nama_ruas_jalan,
				}
			})}
			dropdownCategory2={dataCategory.data2.map((data) => {
				return {
					value: data.id_kategori_perjal,
					label: data.nama_kategori_perjal,
				}
			})}
			// state={dataCategory.data2.kategori_perjal.nama_perjals.map(
			// 	(data) => {
			// 		return {
			// 			value: data.id_nama_perjal,
			// 			label: data.nama_perjal,
			// 		}
			// 	}
			// )}
			nameCategory1={"Nama Ruas Jalan"}
			nameCategory2={"Nama Kategori Perjal"}
			fetchData={fetchData}
			HandlerFilterDropDown={HandlerFilterDropDown}
		/>
	)
}

export default PageTerpasangPerjal
