const base_url = "https://api.sipanja.lampungprov.go.id:9069/api/"
const base_public_url = "https://api.sipanja.lampungprov.go.id:9069/"

export const APIKabupaten = {
	edit: base_url + "kabupaten/patch/",
	delete: base_url + "kabupaten/delete/",
	create: base_url + "kabupaten/post",
	show: base_url + "kabupaten/show",
	showDetail: base_url + "kabupaten/get/",
	showRuasByKabupaten: base_url + "kabupaten/show/ruas-jalan",
}

export const APIKategoriPerjal = {
	edit: base_url + "kategori-perjal/patch/",
	delete: base_url + "kategori-perjal/delete/",
	create: base_url + "kategori-perjal/post",
	show: base_url + "kategori-perjal/show",
	showDetail: base_url + "kategori-perjal/get/",
	kategoriParams: base_url + "kategori-perjal/show/nama-perjal",
}

export const APINamaPerjal = {
	edit: base_url + "nama-perjal/patch/",
	delete: base_url + "nama-perjal/delete/",
	create: base_url + "nama-perjal/post",
	show: base_url + "nama-perjal/show",
	showDetail: base_url + "nama-perjal/get/",
}

export const APIRuasJalan = {
	edit: base_url + "ruas-jalan/patch/",
	delete: base_url + "ruas-jalan/delete/",
	create: base_url + "ruas-jalan/post",
	show: base_url + "ruas-jalan/show",
	showDetail: base_url + "ruas-jalan/get/",
	showApiByKabupaten: base_url + "ruas-jalan/get/kabupaten/",
}

export const APIDRK = {
	edit: base_url + "drk/patch/",
	delete: base_url + "drk/delete/",
	create: base_url + "drk/post",
	show: base_url + "drk/show",
	showDetail: base_url + "drk/get/",
}

export const APISaranAduan = {
	edit: base_url + "saran-dan-aduan/patch/",
	delete: base_url + "saran-dan-aduan/delete/",
	create: base_url + "saran-dan-aduan/post",
	show: base_url + "saran-dan-aduan/show",
	showDetail: base_url + "saran-dan-aduan/get/",
}

export const APIPerjal = {
	edit: base_url + "perjal/patch/",
	delete: base_url + "perjal/delete/",
	create: base_url + "perjal/post",
	show: base_url + "perjal/show",
	showDetail: base_url + "perjal/get/",
	perjalParams: base_url + "perjal/show/linked",
	perjalParamsNeeded: base_url + "perjal/show/linked/needed",
	perjalParamsOwn: base_url + "perjal/show/linked/Own",
	perjalParamsPriority: base_url + "perjal/show/linked/priority",
}

export const APICounter = {
	count: base_url + "count/summary",
}

export const APIUser = {
	show: base_url + "token",
}

export const APIPublic = {
	show: base_url + "kabupaten-perjal/count/kebutuhan/",
	show_terpasang: base_url + "kabupaten-perjal/count/terpasang/",
	show_prioritas: base_url + "kabupaten-perjal/count/prioritas/",
}

export const APILogin = {
	token: base_public_url + "token",
}

export const APIAdmin = {
	edit: base_public_url + "users/patch/",
	delete: base_public_url + "users/delete/",
	create: base_public_url + "users/post",
	show: base_public_url + "users/show",
	showDetail: base_public_url + "users/get/",
}
