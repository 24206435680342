import React from "react"
import {
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	Select,
} from "@chakra-ui/react"
import { Field } from "formik"

export const FormInputSelectedPublic = ({
	id,
	name,
	errors,
	touched,
	options,
	placeholder,
}) => {
	return (
		<FormControl isInvalid={!!errors && touched}>
			<FormLabel htmlFor={id}>{name}</FormLabel>
			<Field
				as={Select}
				id={id}
				name={id}
				type={id}
				placeholder={placeholder}
			>
				{options.map((option) => (
					<option value={option.value}>{option.label}</option>
				))}
			</Field>
			<FormErrorMessage>{errors}</FormErrorMessage>
		</FormControl>
	)
}

export const FormInputSelectedChangePublic = ({
	id,
	name,
	errors,
	touched,
	options,
	onChange,
	placeholder,
}) => {
	return (
		<FormControl isInvalid={!!errors && touched}>
			<FormLabel htmlFor={id}>{name}</FormLabel>
			<Field
				as={Select}
				id={id}
				name={id}
				type={id}
				onChange={onChange}
				placeholder={placeholder}
			>
				{options.map((option) => (
					<option value={option.value}>{option.label}</option>
				))}
			</Field>
			<FormErrorMessage>{errors}</FormErrorMessage>
		</FormControl>
	)
}
