import React from "react"
import { Box } from "@chakra-ui/react"
import TableContent from "../Content/tablecontent"

const ContainerBox = ({
	user,
	Create,
	name,
	records,
	columns,
	filter,
	linkCreate,
	expand,
	ComponentExpanded,
	category,
	saran,
	dropdown,
	dropdownCategory1,
	dropdownCategory2,
	nameCategory1,
	nameCategory2,
	loading,
	fetchData,
	HandlerFilterDropDown,
}) => {
	return (
		<Box
			alignContent={"center"}
			bg="white"
			borderRadius={{
				base: "none",
				md: "lg",
			}}
			boxShadow={{
				base: "none",
				md: "lg",
			}}
			border={{
				base: "none",
				md: "1px solid #e2e8f0",
			}}
			my={{
				base: 0,
				md: 5,
			}}
			p={{
				base: 0,
				md: 5,
			}}
			alignSelf={"center"}
			flexDir={{ base: "column", md: "row", lg: "row" }}
			width={{ base: "100%", md: "95%", lg: "95%", xl: "100%" }}
			maxWidth={{ base: "100%", md: "100%", lg: "100%", xl: "95%" }}
		>
			<TableContent
				user={user}
				Create={Create}
				name={name}
				records={records}
				columns={columns}
				filter={filter}
				linkCreate={linkCreate}
				expand={expand}
				ComponentExpanded={ComponentExpanded}
				category={category}
				saran={saran}
				dropdown={dropdown}
				dropdownCategory1={dropdownCategory1}
				dropdownCategory2={dropdownCategory2}
				nameCategory1={nameCategory1}
				nameCategory2={nameCategory2}
				progressPending={loading}
				fetchData={fetchData}
				HandlerFilterDropDown={HandlerFilterDropDown}
			/>
		</Box>
	)
}

export default ContainerBox
